// material-ui
import { Typography } from "@mui/material";

// project import
import MainCard from "components/MainCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadingCountriesEffect } from "store/effects/countriesEffects";
import { loadingUsers } from "store/effects/usersEffect";
import {
  convertProspectEffect,
  deleteProspectEffect,
  editProspectEffect,
  listProspectEffect,
} from "store/effects/prospectEffects";
// import CreateProspect from './createProspect';
import { LinearProgress, CircularProgress } from "@mui/material";

import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Tooltip from "@mui/material/Tooltip";
// material-ui
import {
  Box,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  TextField,
  Checkbox,
  Select,
  FormControl,
  Grid,
  InputLabel,
  Autocomplete,
} from "@mui/material";

// Icons
import {
  DeleteOutlined,
  UserSwitchOutlined,
  EyeOutlined,
} from "@ant-design/icons";

// project import
import Dot from "components/@extended/Dot";
import _ from "lodash";
// import EditProspect from './editProspect';
import LoadingButton from "@mui/lab/LoadingButton";
import SecureLS from "secure-ls";
import moment from "moment";
import { Link } from "react-router-dom";
// import NewUser from "./components/NewUser";
import UsersListActions from "pages/users/components/UsersOptions";

// import {}

let ls = new SecureLS({ encodingType: "aes" });
const label = { inputProps: { "aria-label": "Checkbox demo" } };

// ==============================|| PROSPECTS LIST ||============================== //

function OrderTableHead({ handleSelectAll, isAllSelected }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          {" "}
          <Checkbox
            {...label}
            checked={isAllSelected}
            onClick={() => handleSelectAll(isAllSelected)}
          />
        </TableCell>
        <TableCell>First name</TableCell>
        <TableCell>Last name</TableCell>
        <TableCell>Email</TableCell>
        <TableCell>Phone number</TableCell>
        {/* <TableCell>Account type</TableCell> */}
        <TableCell>Is Active</TableCell>
        <TableCell>Country</TableCell>
        <TableCell>Joined at</TableCell>
        <TableCell>Last updated</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

OrderTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

// ==============================|| ORDER TABLE - STATUS ||============================== //

const OrderStatus = ({ status }) => {
  let color;
  let title;

  switch (status) {
    case 0:
      color = "warning";
      title = "Pending";
      break;
    case 1:
      color = "success";
      title = "Approved";
      break;
    case 2:
      color = "error";
      title = "Rejected";
      break;
    default:
      color = "primary";
      title = "None";
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Dot color={color} />
      <Typography>{title}</Typography>
    </Stack>
  );
};

OrderStatus.propTypes = {
  status: PropTypes.number,
};

// ==============================|| ORDER TABLE ||============================== //

export default function ToConvert() {
  const [order] = useState("asc");
  const [orderBy] = useState("trackingNo");
  const [selected] = useState([]);
  const [selectedProspect, setSelectedProspect] = useState([]);
  // const [selectedProspect, setSelectedProspect] = useState([]);
  const [search, setSearch] = useState("");
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [open, setOpen] = useState({ open: false, id: "" });
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState("");
  const [userSex, setUserSex] = useState("");
  const [country, setCountry] = useState("");
  const [joinedDate, setJoinedDate] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(+event.target.value);
    setPage(0);
  };
  // handle change bulk status
  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  const {
    list_propect_success,
    delete_propect_success,
    delete_propect_request,
    edit_propect_request,
    list_propect_request,

    prospectsList: { results, count, page: page1 },
  } = useSelector((state) => state.prospects);
  const { countriesList, loading_countries_success } = useSelector(
    (state) => state.countries
  );

  const { groups } = ls.get("user-details");
  // console.log('user ===========> ', user);
  const handleSelectProspect = (prospect) => {
    setSelectedProspect((prev) => [...prev, prospect.id]);
    if (selectedProspect.length === results.length) {
      setIsAllSelected(true);
    }

    // setIsAllSelected(true);
  };
  const handleDeSelectProspect = (id) => {
    setIsAllSelected(false);
    if (selectedProspect.includes(id)) {
      setSelectedProspect((prev) => prev.filter((i) => i !== id));
      setIsAllSelected(false);
    }
  };
  const handleSelectAllProspects = (isAllSelected) => {
    // console.log("leflrklrklr =========== ", selectedProspect, isAllSelected);
    if (isAllSelected) {
      setSelectedProspect([]);
      setIsAllSelected(false);
    } else {
      setIsAllSelected(true);
      results.forEach((prospect) => {
        handleSelectProspect(prospect, true);
      });
    }
  };
  const dispatch = useDispatch();
  const onConvertSuccess = (id) => {
    setSelectedProspect((prev) => prev.filter((i) => i !== id));
    if (selectedProspect.length !== results.length) {
      setIsAllSelected(false);
    }
  };

  const handleConvertProspectAll = () => {
    selectedProspect.forEach((element) => {
      dispatch(
        editProspectEffect({ to_convert: true, id: element }, onConvertSuccess)
      );
    });
  };
  useEffect(() => {
    dispatch(loadingCountriesEffect());
  }, []);
  useEffect(() => {
    dispatch(listProspectEffect(search, pageSize, page + 1, true));
  }, [pageSize, page, search]);

  useEffect(() => {
    if (delete_propect_success) {
      setOpen({ open: false, id: "" });
    }
  }, [delete_propect_success]);

  const isSelected = (trackingNo) => selected.indexOf(trackingNo) !== -1;

  return (
    <Grid container spacing={4}>
      <Grid item md={12}>
        <Grid>
          {/* <Typography variant="h4">Filters</Typography> */}
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <Box>
              <InputLabel>Search</InputLabel>
              <TextField
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                id="outlined-basic"
                placeholder="Search by name, user id, email"
                variant="outlined"
              />
            </Box>

            {/* <Box>
              <Button
                onClick={handleFilter}
                sx={{ marginTop: 3.5, height: "40px" }}
                variant="contained"
                color="primary"
              >
                Filter
              </Button> 
            </Box>*/}
          </Stack>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <MainCard
          sx={{ "& .MuiCardHeader-action": { width: "90%" } }}
          title="Users list"
          secondary={
            <Box
              sx={{
                display: "flex",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                {selectedProspect.length >= 1 ? (
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ marginRight: "1rem" }}
                  >
                    {edit_propect_request && selectedProspect.length > 0 ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Tooltip title="Convert prospect to user">
                        <IconButton onClick={() => handleConvertProspectAll()}>
                          <UserSwitchOutlined style={{ color: "green" }} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {/* {loading_countries_success && <EditProspect prospect={row} groups={groups} />} */}
                    <Tooltip title="Delete user">
                      <IconButton onClick={() => setOpen({ open: true })}>
                        <DeleteOutlined />
                      </IconButton>
                    </Tooltip>

                    <Dialog
                      open={open.open}
                      onClose={() => setOpen({ open: false, id: "" })}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Do you really want to delete selected prospects?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          This action is irreversible. Are you sure to continue
                          ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => setOpen({ open: false, id: "" })}
                        >
                          Cancel
                        </Button>
                        <LoadingButton
                          loading={delete_propect_request}
                          variant="primary"
                          color="error"
                          onClick={() => {
                            selectedProspect.forEach((element) => {
                              dispatch(
                                deleteProspectEffect({ id: element }),
                                onConvertSuccess
                              );
                            });
                          }}
                        >
                          Yes, delete
                        </LoadingButton>
                      </DialogActions>
                    </Dialog>
                    {/* bulk Change status */}
                    <Box sx={{ width: "200px" }}>
                      <FormControl fullWidth>
                        <Select
                          labelId="bulk-change-status-label"
                          id="bulk-change-status"
                          // value={status}
                          defaultValue={"NORMAL_USERS"}
                          name="status"
                          label="Status"
                          onChange={(event) => {
                            const value = event.target.value;
                            if (value !== "NO STATUS") {
                              selectedProspect.forEach((element) => {
                                dispatch(
                                  editProspectEffect(
                                    { status: value, id: element },
                                    onConvertSuccess
                                  )
                                );
                              });
                            }
                          }}
                          // error={Boolean(touched.status && errors.status)}
                        >
                          {["MANAGER", "NORMAL_USERS"].map((item, index) => (
                            <MenuItem key={"menu-item-" + index} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: "flex" }}>
                      <Typography sx={{ m: "auto" }}>
                        {selectedProspect.length} Users
                        {selectedProspect.length > 1 ? "s" : ""} selected
                      </Typography>{" "}
                    </Box>
                  </Stack>
                ) : (
                  <div />
                )}
              </Box>
              {/* <Stack direction="row" spacing={2}>
                <NewUser
                  actionButton={() => (
                    <Button
                      variant="contained"
                      sx={{ width: "200px", marginLeft: "auto" }}
                      color="primary"
                    >
                      Create a manager
                    </Button>
                  )}
                  isManager={true}
                  handleSuccess={handleFilter}
                />
              </Stack> */}
            </Box>
          }
        >
          {list_propect_request && <LinearProgress />}
          {/* {list_propect_request && results.length === 0 && <LinearProgress />} */}
          <Box>
            <TableContainer
              sx={{
                width: "100%",
                overflowX: "auto",
                position: "relative",
                display: "block",
                maxWidth: "100%",
                "& td, & th": { whiteSpace: "nowrap" },
              }}
            >
              <Table
                aria-labelledby="tableTitle"
                sx={{
                  "& .MuiTableCell-root:first-child": {
                    pl: 2,
                  },
                  "& .MuiTableCell-root:last-child": {
                    pr: 3,
                  },
                }}
              >
                <OrderTableHead
                  order={order}
                  orderBy={orderBy}
                  handleSelectAll={handleSelectAllProspects}
                  isAllSelected={isAllSelected}
                />
                <TableBody>
                  {results.map((row1, index) => {
                    const row = { ...row1 };
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${row.id}`;
                    row.countryDetails = _.filter(countriesList, (item) =>
                      item.url.includes(`/${row.country}/`)
                    )[0];

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        aria-checked={selectedProspect.includes(row.id)}
                        tabIndex={-1}
                        key={row.id}
                        selected={selectedProspect.includes(row.id)}
                      >
                        <TableCell>
                          {" "}
                          <Checkbox
                            {...label}
                            checked={selectedProspect.includes(row.id)}
                            onClick={() =>
                              selectedProspect.includes(row.id)
                                ? handleDeSelectProspect(row.id)
                                : handleSelectProspect(row)
                            }
                          />
                        </TableCell>
                        <TableCell align="left">{row.first_name}</TableCell>
                        <TableCell align="left">{row.last_name}</TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          align="left"
                        >
                          <a
                            style={{ color: "#262626" }}
                            href={`mailto:${row.email}`}
                          >
                            {row.email}
                          </a>
                        </TableCell>
                        <TableCell align="left">{row.phone_number}</TableCell>
                        {/* <TableCell align="left">{row.account_type}</TableCell> */}
                        <TableCell align="left">
                          {row.is_active ? "YES" : "NO"}
                        </TableCell>
                        <TableCell align="left">
                          {row?.country?.name}
                        </TableCell>
                        <TableCell align="left">
                          {moment(row.created_at).format("lll")}
                        </TableCell>
                        <TableCell align="left">
                          {moment(row.last_updated).format("lll")}
                        </TableCell>
                        <TableCell align="left" sx={{ display: "flex" }}>
                          <UsersListActions user={row} view={"PROSPECTS"} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={count}
                rowsPerPage={pageSize}
                page={page1 - 1 ?? 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
            {list_propect_success && results.length === 0 && (
              <Typography variant="body2"> No prospects to convert</Typography>
            )}
          </Box>
        </MainCard>
      </Grid>
    </Grid>
  );
}
