import {
  Grid,
  Card,
  Typography,
  Stack,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Fade,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";

import PropTypes from "prop-types";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import moment from "moment";
import NewWallet from "./create/NewWallet";
import { MoreOutlined } from "@ant-design/icons";
import { LinearProgress } from "@mui/material";
import "./styles.css";
import "./bootstrap.css";

// Icons
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import HideIfNotAdmin from "components/auth/HideIfNotAdmin";
import Swal from "sweetalert2";

function WalletMenu({ wallet, handleSuccess }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ position: "absolute", bottom: "1rem", right: "1rem" }}
      >
        <MoreOutlined style={{ color: "#fff" }} />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <NewWallet
          handleSuccess={handleSuccess}
          wallet={wallet}
          isEdition={true}
          actionButton={() => <MenuItem>Update</MenuItem>}
        />
      </Menu>
    </>
  );
}

// ==============================|| PROSPECTS LIST ||============================== //

function OrderTableHead({ handleSelectAll, isAllSelected }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          {" "}
          <Checkbox
            checked={isAllSelected}
            onClick={() => handleSelectAll(isAllSelected)}
          />
        </TableCell>
        <TableCell>Reference</TableCell>
        <TableCell>Amount</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Date</TableCell>
      </TableRow>
    </TableHead>
  );
}

OrderTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

export default function GlobalWalletsList() {
  const { userId } = useParams();
  const [wallets, setWallets] = useState([]);

  const [loading, setLoading] = useState(true);
  const [selectedWallet, setSelectedWallet] = useState();
  const toastId = React.useRef(null);

  const handleSuccess = (wallet) => {
    API.get(URLS.WALLETS.GLOBALS).then((resp) => {
      setWallets(resp.data);
    });
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )
        toastId.current = toast("Deleting...", {
          autoClose: false,
          closeButton: true,
        });

        API.delete(URLS.WALLETS.DELETE(selectedWallet.id))
          .then((resp) => {
            toast.update(toastId.current, {
              render: "Wallet successfuly deleted.",
              type: toast.TYPE.SUCCESS,
              autoClose: 5000,
              toastId: toastId,
            });
            handleSuccess();
          })
          .catch((error) => {
            toast.update(toastId.current, {
              render:
                error?.response?.detail ?? "Error while deleting the wallet.",
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              toastId: toastId,
            });
          });
      }
    });
  };

  useEffect(() => {
    API.get(URLS.WALLETS.GLOBALS)
      .then((resp) => {
        setWallets(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <Grid container spacing={3}>
      <HideIfNotAdmin>
        <Grid item md={12}>
          <Stack justifyContent="end">
            <NewWallet
              isGlobal
              handleSuccess={handleSuccess}
              actionButton={() => (
                <Button
                  variant="contained"
                  sx={{ width: "200px", marginLeft: "auto" }}
                  color="primary"
                >
                  New wallet
                </Button>
              )}
            />
          </Stack>
        </Grid>
      </HideIfNotAdmin>
      <Grid item md={12}>
        <Grid>{loading && <LinearProgress />}</Grid>
      </Grid>
      <Grid item md={12} p={3}>
        <Grid>
          <div class="crypt-deepblue-segment p-2">
            {!loading && wallets?.length === 0 && (
              <Typography sx={{ textAlign: "left" }} variant="body2">
                No Wallet found.
              </Typography>
            )}
            {/* <form class="crypt-dash-search">
                          <input type="search" placeholder="Search..." name="s" class="crypt-big-search"/>
                          <button type="submit">
                              <i class="pe-7s-search"></i>
                          </button>
                      </form> */}
            <div className="row">
              {wallets.map((wallet) => {
                return (
                  <div className="col-md-6 col-12 mb-3 px-2" key={wallet.id}>
                    <div>
                      <ul
                        class="crypt-big-list crypt-coin-select global-wallets"
                        style={{ display: "flex", gap: "1em" }}
                      >
                        <li className="mb-0 w-100">
                          <NewWallet
                            isEdition
                            wallet={wallet}
                            isGlobal
                            handleSuccess={handleSuccess}
                            actionButton={() => (
                              <a
                                href="#bitcoin"
                                className={
                                  selectedWallet?.id === wallet.id
                                    ? "active"
                                    : ""
                                }
                                onClick={(event) => {
                                  event.preventDefault();
                                  setSelectedWallet(wallet);
                                }}
                              >
                                <img
                                  src={`/assets/img/${wallet?.currency?.toLowerCase()}.png`}
                                  height="25"
                                  className="crypt-market-cap-logo pr-2"
                                  alt="coin"
                                />{" "}
                                {wallet?.currency}
                                {wallet?.address_type
                                  ? ` (${wallet?.address_type})`
                                  : ""}{" "}
                                <p className="fright">
                                  <b>{wallet.balance}</b>
                                </p>
                              </a>
                            )}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
              
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
