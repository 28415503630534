import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseOutlined from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";

import { Link as RouterLink } from "react-router-dom";
// material-ui
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

import AnimateButton from "components/@extended/AnimateButton";

// assets
import { useDispatch, useSelector } from "react-redux";

// actions
import { useParams } from "react-router";
import LoadingButton from "@mui/lab/LoadingButton";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { toast } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined style={{ color: "#000" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function NewReport({
  toEdit,
  isEdition,
  actionButton,
  handleSuccess,
}) {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState(toEdit?.title ?? "");
  const [creating, setCreating] = React.useState(false);

  //   Report content
  const editorRef = React.useRef(null);
  const getReportContent = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const { userId } = useParams();
  const {
    login_request,
    auth: { user },
  } = useSelector((state) => state.userAuth);

  const handleCreateReport = (values) => {
    if (title && editorRef.current) {
      setCreating(true);
      const data = {
        title: title,
        description: editorRef.current.getContent(),
        created_by: user.id,
        user: userId,
      };

      if (isEdition && toEdit) {
        API.patch(URLS.REPORTS.EDIT(toEdit?.id), data)
        .then((resp) => {
          setCreating(false);
          handleClose();
          if (handleSuccess) {
            handleSuccess(resp.data);
          }
          toast.success("Report edited successfuly");
        })
        .catch((error) => {
          setCreating(false);
          toast.error(error?.response?.data.detail ?? "Report edition failed");
        });
      } else {
        API.post(URLS.REPORTS.CREATE, data)
          .then((resp) => {
            setCreating(false);
            handleClose();
            if (handleSuccess) {
              handleSuccess(resp.data);
            }
            toast.success("Report created successfuly");
          })
          .catch((error) => {
            setCreating(false);
            toast.error(
              error?.response?.data.detail ?? "Report creation failed"
            );
          });
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div onClick={handleClickOpen}>{actionButton()}</div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {isEdition ? "Edit Report" : "New Report"}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              title: "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().max(255).required("This field is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {}}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="title-login">Title</InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.title && errors.title)}
                        id="title-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={title}
                        type="text"
                        min={0}
                        name="title"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setTitle(event.target.value);
                          handleChange(event);
                        }}
                        placeholder="Title"
                      />
                      {touched.title && errors.title && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-firstName-login"
                        >
                          {errors.title}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Editor
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue={toEdit?.description ?? "<p></p>"}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="files-login">Join files</InputLabel>
                      <OutlinedInput
                        fullWidth
                        id="files-login"
                        // type={showPassword ? 'text' : 'password'}
                        // value={files}
                        type="file"
                        onChange={handleChangeFiles}
                        placeholder="Email address"
                        inputProps={{ multiple: true }}
                        accept="application/pdf, image/png"
                      />
                      {touched.email && errors.email && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-email-login"
                        >
                          {errors.email}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid> */}

                  <Grid item md={6} xs={12} sx={{ mt: "0" }}></Grid>
                  {errors.submit && (
                    <Grid item xs={12}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{ height: "0px", visibility: "hidden" }}
                  >
                    <AnimateButton>
                      <Button
                        disableElevation
                        disabled={login_request}
                        id="submitButton"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Submit
                        {/* {login_request && <CircularLo /> } */}
                      </Button>
                    </AnimateButton>
                  </Grid>
                  {/* <Grid item xs={12}>
                                <Divider>
                                    <Typography variant="caption"> Login with</Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <FirebaseSocial />
                            </Grid> */}
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={creating}
            onClick={handleCreateReport}
          >
            {isEdition ? "Save changes" : "Create"}
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
