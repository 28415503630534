import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import LinearProgress from "@mui/material/LinearProgress";
// import PersonIcon from "@mui/icons-material/Person";
// import AddIcon from "@mui/icons-material/Add";
import { UserOutlined } from "@ant-design/icons";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import { useDispatch } from "react-redux";
import { loadingUsers } from "store/effects/usersEffect";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import _ from "lodash";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";

const emails = ["username@gmail.com", "user02@gmail.com"];

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SelectUsers({ onUserSelect, many, actionButton, dialogTitle, actionLabel, userType }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [pageSize, setPageSize] = React.useState(25);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const handleDelete = (value) => {
    setSelectedUser((prev) => prev.filter((user) => user.id !== value.id));
  };

  const handleSelect = (users) => {
    handleClose();
    onUserSelect(selectedUser)
  };

  const handleListItemClick = (value) => {
    // handleClose(value);
    // console.log(value, "ldklfkldk");
    // console.log(_.filter(selectedUser, { id: value.id }) === 0, selectedUser);
    if (_.filter(selectedUser, { id: value.id }).length === 0) {
      setSelectedUser((prev) => many ? [...prev, value] : [value]);
    } else {
      // console.log(
      //   _.filter(selectedUser, { id: value.id }) === 0,
      //   "_.filter(selectedUser, { id: value.id }) === 0oo"
      // );
      setSelectedUser((prev) => prev.filter((user) => user.id !== value.id));
    }
  };

  React.useEffect(() => {
    setLoading(true);
    API.get(userType === "TEAM_LEADER" ? URLS.USERS.TEAM_LEADER_LIST(search) : URLS.USERS.MANAGER(search, 1, true, false, ""))
      .then((resp) => {
        const { data } = resp;
        setUsers(data.results);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [pageSize, search]);


  return (
    <div>
      <div role={"button"} onClick={handleClickOpen}>
        {actionButton()}
      </div>
      <Dialog maxWidth={"xs"} fullWidth onClose={handleClose} open={open}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <Divider />
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "96%" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            placeholder="Search user"
            variant="outlined"
            onChange={(event) => setSearch(event.target.value)}
          />
        </Box>
        <Box m={1}>{loading && <LinearProgress />}</Box>
        <Box m={1}>
          <Stack direction="row" spacing={1}>
            {selectedUser.map((user) => {
              return (
                <Chip
                  key={"chip_" + user.id}
                  label={user.first_name + " " + user.last_name}
                  variant="outlined"
                  // onClick={handleClick}
                  onDelete={() => handleDelete(user)}
                />
              );
            })}
          </Stack>
        </Box>

        <List sx={{ pt: 0 }}>
          {users.map((user) => (
            <ListItem disableGutters>
              <ListItemButton
                onClick={() => handleListItemClick(user)}
                key={user.id}
                selected={_.filter(selectedUser, { id: user.id }).length >= 1}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                    <UserOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={user.first_name + " " + user.last_name}
                  secondary={user.email}
                />
              </ListItemButton>
            </ListItem>
          ))}

          {/* <ListItem disableGutters>
            <ListItemButton
              autoFocus
              onClick={() => handleListItemClick("addAccount")}
            >
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add account" />
            </ListItemButton>
          </ListItem> */}
        </List>
        <Box m={1}>
          {!loading && users.length === 0 && (
            <Typography variant="body2">No user found</Typography>
          )}
        </Box>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button variant="contained" disabled={selectedUser.length === 0} onClick={handleSelect}>{actionLabel}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
