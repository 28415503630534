import { useEffect, useState } from "react";
import {
  Conversation,
  ConversationHeader,
  ChatContainer,
  MessageList,
  Message,
  VoiceCallButton,
  VideoCallButton,
  Search,
  Avatar,
  MessageInput,
  MainContainer,
  Sidebar,
  ConversationList,
  EllipsisButton,
} from "@chatscope/chat-ui-kit-react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import { URLS } from "services/urls/urls";
import { API } from "services/axios";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

export default function UsersMessages() {
  //   const [conversations, setConversations] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selectedConv, setSelectedConv] = useState();
  const [loadingMessages, setLoadingMessages] = useState(false);
  //   const [loadinConv, setLoadingConv] = useState(true);
  const { user } = useSelector((state) => state.userAuth.auth);

  const handleSelectConv = (conv) => {
    setLoadingMessages(true);
    setSelectedConv(conv);
    setMessages([]);
    API.get(URLS.MESSAGES.ROOM_MESSAGES(conv.id))
      .then((resp) => {
        const { data } = resp;
        setMessages(data);
        setLoadingMessages(false);
      })
      .catch((error) => {
        setLoadingMessages(false);
      });
  };
  const handleSendMessage = (html, textContent) => {
    const toSend = {
      content: textContent,
      room: selectedConv.id,
      sender: user.id,
    };
    const idToast = toast.loading("Sending message. Please wait...");
    API.post(URLS.MESSAGES.CREATE, toSend)
      .then((response) => {
        const { data } = response;
        setMessages((prevState) => [...prevState, data]);
        toast.update(idToast, {
          render: "Message sended successfully",
          type: "success",
          isLoading: false,
        });
        // history.push('/admin/trades');
      })
      .catch((error) => {
        toast.update(idToast, {
          render:
            error.response.data.detail ??
            "Message creation error!! Please try later",
          type: "error",
          isLoading: false,
          autoClose: true,
        });
        // const errorMessage = formatError(error.response.data);
        // dispatch(loginFailedAction(errorMessage));
      });
  };

  const {
    isLoading: loadinConv,
    error,
    data: conversations,
    isFetching,
  } = useQuery(["repoData"], () =>
    API.get(URLS.MESSAGES.ROOMS.MY).then((res) => res.data)
  );
  //   useEffect(() => {
  //     setLoadingConv(true);
  //     API.get(URLS.MESSAGES.ROOMS.MY).then((response) => {
  //       const { data } = response;
  //       setConversations(data);
  //       setLoadingConv(false);
  //     }).catch(err => {

  //         setLoadingConv(false);
  //     });
  //   }, []);

  return (
    <div
      style={{
        height: "600px",
        position: "relative",
      }}
    >
      <MainContainer responsive>
        <Sidebar position="left" scrollable={false}>
          <Search placeholder="Search..." />
          <ConversationList loading={loadinConv}>
            {conversations?.map((conv) => {
              return (
                <Conversation
                  name={conv.title}
                  onClick={() => handleSelectConv(conv)}
                  //   lastSenderName="Lilly"
                  //   info="Yes i can do it for you"
                >
                  <Avatar
                    src={
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                    }
                    name="Lilly"
                    status="available"
                  />
                </Conversation>
              );
            })}
          </ConversationList>
        </Sidebar>

        <ChatContainer>
          {selectedConv && (
            <ConversationHeader>
              <ConversationHeader.Back />
              <Avatar
                src={
                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                }
                name="Zoe"
              />
              <ConversationHeader.Content
                userName={selectedConv.title}
                info="Active 10 mins ago"
              />
              <ConversationHeader.Actions>
                <VoiceCallButton />
                <VideoCallButton />
                <EllipsisButton orientation="vertical" />
              </ConversationHeader.Actions>
            </ConversationHeader>
          )}
          <MessageList
            // typingIndicator={<TypingIndicator content="Zoe is typing" />}
            loading={loadingMessages}
          >
            {messages.map((message) => {
              return (
                <Message
                  key={message.id}
                  model={{
                    message: message.content,
                    sentTime: message.created_at,
                    sender: message.sender.first_name,
                    direction:
                      message.sender === user.id
                        ? "outgoing"
                        : message.sender.id === user.id
                        ? "outgoing"
                        : "incoming",
                    position: "single",
                  }}
                />
              );
            })}
          </MessageList>
          <MessageInput
            placeholder="Type message here"
            onSend={handleSendMessage}
          />
        </ChatContainer>
      </MainContainer>
    </div>
  );
}
