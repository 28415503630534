import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseOutlined from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { toast } from "react-toastify";
// material-ui
import {
  Button,
  Typography,
  Grid,
  IconButton,
  Divider,
  Stack,
  Box,
} from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined style={{ color: "#000" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ReportDetails({ report, actionButton, onSuccess }) {
  const [open, setOpen] = React.useState(false);
  const [answering, setAnswering] = React.useState(false);

  const {
    auth: { user },
  } = useSelector((state) => state.userAuth);
  //   Ticket content
  const editorRef = React.useRef(null);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleReply = () => {
    if (editorRef.current) {
      setAnswering(true);

      const data = {
        subject: report?.subject,
        content: editorRef.current.getContent(),
        user: report?.user,
        created_by: user?.id,
        parent: report?.id,
      };
      API.post(URLS.TICKETS.create, data)
        .then((resp) => {
          setAnswering(false);
          editorRef.current.setContent("");
          if (onSuccess) {
            onSuccess();
            handleClose()
          }
        })
        .catch((error) => {
          setAnswering(false);
        });
    }
  };

  return (
    <div>
      <div onClick={handleClickOpen}>{actionButton()}</div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {"Tickets details"}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} display="flex">
              <Typography variant="h4" sx={{ marginRight: "1rem" }}>
                Reference:{" "}
              </Typography>{" "}
              {"   "}
              <Typography variant="h6" sx={{ lineHeight: "31px" }}>
                #{report?.reference}
              </Typography>
            </Grid>
            <Grid item xs={12} display="flex">
              <Typography variant="h4" sx={{ marginRight: "1rem" }}>
                Title:{" "}
              </Typography>{" "}
              {"   "}
              <Typography variant="h6" sx={{ lineHeight: "31px" }}>
                {report?.subject}
              </Typography>
            </Grid>
            <Grid item xs={12} display="flex">
              <Typography variant="h4" sx={{ marginRight: "1rem" }}>
                Content:{" "}
              </Typography>
              <div dangerouslySetInnerHTML={{ __html: report.content }}></div>
            </Grid>
            {report?.joined_file && (
              <Grid item xs={12} display="flex">
                <Typography variant="h4" sx={{ marginRight: "1rem" }}>
                  Joined file:{" "}
                </Typography>{" "}
                {"   "}
                <Typography variant="h6" sx={{ lineHeight: "31px" }}>
                  <Link to={report?.joined_file}>to={report?.joined_file}</Link>
                </Typography>
              </Grid>
            )}
            {/* <Grid item Reportxs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4">Joined files: </Typography>
                <Typography variant="h6">File 1,</Typography>
                <Typography variant="h6">File 2,</Typography>
            </Grid> */}
          </Grid>
          <Grid container mt={3}>
            <Grid item md={12}>
              <Divider />
              <Typography variant="h4" sx={{ margin: "1rem 0" }}>
                Tickets replies{" "}
              </Typography>{" "}
              <Grid p={3} sx={{ backgroundColor: "#c5c5c582" }}>
                <Grid mb={3}>
                  {report?.responses?.map((response) => {
                    return (
                      <>
                        <Grid item xs={12} display="flex">
                          <Typography variant="h4" sx={{ marginRight: "1rem" }}>
                            Reference:{" "}
                          </Typography>{" "}
                          {"   "}
                          <Typography variant="h6" sx={{ lineHeight: "31px" }}>
                            #{response?.reference}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} display="flex">
                          <Typography variant="h4" sx={{ marginRight: "1rem" }}>
                            Author:{" "}
                          </Typography>{" "}
                          {"   "}
                          <Typography variant="h6" sx={{ lineHeight: "31px" }}>
                            {response?.created_by?.first_name}{" "}
                            {response?.created_by?.last_name}
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={12} display="flex">
                          <Typography variant="h4" sx={{ marginRight: "1rem" }}>
                            Title:{" "}
                          </Typography>{" "}
                          {"   "}
                          <Typography variant="h6" sx={{ lineHeight: "31px" }}>
                            {response?.subject}
                          </Typography> */}
                        {/* </Grid> */}
                        <Grid item xs={12} display="flex">
                          <Typography variant="h4" sx={{ marginRight: "1rem" }}>
                            Content:{" "}
                          </Typography>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: response.content,
                            }}
                          ></div>
                        </Grid>
                        {response?.joined_file && (
                          <Grid item xs={12} display="flex">
                            <Typography
                              variant="h4"
                              sx={{ marginRight: "1rem" }}
                            >
                              Joined file:{" "}
                            </Typography>{" "}
                            {"   "}
                            <Typography
                              variant="h6"
                              sx={{ lineHeight: "31px" }}
                            >
                              <Link to={response?.joined_file}>
                                to={response?.joined_file}
                              </Link>
                            </Typography>
                          </Grid>
                        )}
                        <Divider />
                      </>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item md={12}>
              <Divider />
              <Typography variant="h4" sx={{ margin: "1rem 0" }}>
                Add a reply{" "}
              </Typography>{" "}
            </Grid>
            {"   "}
            <Grid item md={12}>
              <Editor
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={"<p></p>"}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
              <Grid>
                <Stack>
                  <Box padding="1rem 0" ml="auto">
                    <Button
                      disabled={answering}
                      onClick={handleReply}
                      variant="contained"
                    >
                      Answer
                    </Button>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
