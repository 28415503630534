import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import _ from "lodash";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function NewUserGroup({ onSuccess, isEdition, toEdit }) {
  const [open, setOpen] = React.useState(false);

  const [permissionsList, setPermissionsList] = React.useState([]);
  const [permissions, setPermissions] = React.useState([]);

  const [creating, setCreating] = React.useState(false);

  const {
    auth: { user },
  } = useSelector((state) => state.userAuth);

  const [groupName, setGroupName] = React.useState(
    isEdition ? toEdit?.name : ""
  );
  const [qrCode, setQrCode] = React.useState();

  //   Transfert list
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState(isEdition ? toEdit?.permissions : []);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items) => (
    <Paper sx={{ width: 200, height: 230, overflow: "auto" }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.id}-label`;

          return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.name}`} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCreate = () => {
    if (groupName && right.length > 1) {
      setCreating(true);
      let data = {
          "name": groupName,
          "permissions": right.map(perm => perm.id)
      }
      if (isEdition) {
        API.put(URLS.PERMISSIONS.GROUPS.UPDATE(toEdit?.id), data)
          .then((resp) => {
            onSuccess(resp.data, true);
            handleClose();
            toast.success(
              `User group successfuly ${
                isEdition ? "edited" : "added"
              }.`
            );
          })
          .catch((error) => {
            setCreating(false);
            toast.error(
              error?.response?.data?.detail ??
                `User group ${isEdition ? "editing" : "creating"} error.`
            );
          });
      } else {
        API.post(URLS.PERMISSIONS.GROUPS.LIST, data)
          .then((resp) => {
            onSuccess(resp.data);
            setCreating(false);
            handleClose();
            toast.success("User group successfuly added.");
            setRight([])
            setGroupName("")
          })
          .catch((error) => {
            setCreating(false);
            toast.error(
              error?.response?.data?.detail ?? "User group creating error."
            );
          });
      }
    }
  };

  const handleChangeFile = (event) => {
    setQrCode(event.target.files[0]);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    API.get(URLS.PERMISSIONS.PERMISSIONS.LIST).then((resp) => {
      const { data } = resp;
      setPermissionsList(data.results);
      if(!isEdition) {
          setLeft(data.results)
      } else {
          const filtered = data.results.filter(item => _.filter(toEdit?.permissions, {id: item.id}).length === 0)
          setLeft(filtered)
      }
    });
  }, []);

  return (
    <div>
      {isEdition ? (
        <MenuItem onClick={handleClickOpen}>
        Edit
      </MenuItem>
      ) : (
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Create group
        </Button>
      )}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={"sm"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          New group informations
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12}>
              <TextField
                sx={{ width: "100%" }}
                id="outlined-account-name"
                label="Group name"
                value={groupName}
                onChange={(event) => setGroupName(event.target.value)}
                variant="outlined"
                size="md"
              />
            </Grid>
            <Grid item md={12} sm={12}>
              <Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>{customList(left)}</Grid>
                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllRight}
                        disabled={left.length === 0}
                        aria-label="move all right"
                      >
                        ≫
                      </Button>
                      <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                      >
                        &gt;
                      </Button>
                      <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                      >
                        &lt;
                      </Button>
                      <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={right.length === 0}
                        aria-label="move all left"
                      >
                        ≪
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item>{customList(right)}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={creating}
            variant="contained"
            color={"primary"}
            onClick={handleCreate}
          >
            {isEdition ? "Save changes" : "Create"}
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
