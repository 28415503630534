// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    loading_countries_request: false,
    loading_countries_success: false,
    loading_countries_failed: false,
    countriesList: []
};

// ==============================|| SLICE - Countries ||============================== //

const countries = createSlice({
    name: 'countries',
    initialState,
    reducers: {
        loadingCountriesRequest(state, action) {
            state.loading_countries_request = true;
            state.loading_countries_success = false;
            state.loading_countries_failed = false;
        },

        loadingCountriesSuccess(state, action) {
            state.loading_countries_request = false;
            state.loading_countries_success = true;
            state.loading_countries_failed = false;
            state.countriesList = action.payload;
        },
        loadingCountriesFailed(state, action) {
            state.loading_countries_request = false;
            state.loading_countries_success = false;
            state.loading_countries_failed = false;
        }
    }
});

export default countries.reducer;

export const { loadingCountriesRequest, loadingCountriesSuccess, loadingCountriesFailed } = countries.actions;
