


export const evaluateClosingPrice = (investment_amount, opening_price, profitLoss) => {


  // # Calculate quantity of BTC purchased
  const quantity_btc = investment_amount / opening_price
  
  const closingPrice = (profitLoss + investment_amount) / quantity_btc;

  
  return closingPrice
}