import { useSelector } from "react-redux"
import { Navigate } from "react-router"



export default function RestrictedToAdmin({children}) {

    const {
        auth: { user }
    } = useSelector((state) => state.userAuth)

    return(
        user.is_superuser ? children : <Navigate to="/unauthorized-route"/>
    )
}