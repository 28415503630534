import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import Error404 from 'pages/errors/not-found';
import Error401 from 'pages/errors/unauth';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: 'login',
            element: <AuthLogin />
        },
        {
          path: "unauthorized-route",
          element: <Error401 />,
        },
        {
          path: "*",
          element: <Error404 />,
        },
        
    ]
};

export default LoginRoutes;
