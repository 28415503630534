import {
  Grid,
  Typography,
  Stack,
  Box,
  CardContent,
  Card,
  CardActionArea,
} from "@mui/material";

import React from "react";
import { Link } from "react-router-dom";
import NewWallet from "pages/users/details/components/create/NewWallet";

const WalletCurrencies = () => {
  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Typography variant="h3">Choose a currency</Typography>
      </Grid>
      <Grid item md={3} sm={4} xs={6}>
        <Card
          component={"div"}
          elevation={1}
          variant="outlined"
          hover
          //   sx={{ p: 2 }}
        >
          <CardActionArea component={Link} to={"/wallets/BTC"}>
            <CardContent>
              <Stack>
                <Box>
                  <Typography noWrap variant="h5">
                    BTC
                  </Typography>
                </Box>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item md={3} sm={4} xs={6}>
        <Card
          component={"div"}
          elevation={1}
          variant="outlined"
          hover
          //   sx={{ p: 2 }}
        >
          <CardActionArea component={Link} to={"/wallets/ETH"}>
            <CardContent>
              <Stack>
                <Box>
                  <Typography noWrap variant="h5">
                    ETH
                  </Typography>
                </Box>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item md={3} sm={4} xs={6}>
        <Card
          component={"div"}
          elevation={1}
          variant="outlined"
          hover
          //   sx={{ p: 2 }}
        >
          <CardActionArea component={Link} to={"/wallets/USDT"}>
            <CardContent>
              <Stack>
                <Box>
                  <Typography noWrap variant="h5">
                    USDT
                  </Typography>
                </Box>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item md={3} sm={4} xs={6}>
        <Card
          component={"div"}
          elevation={1}
          variant="outlined"
          hover
          //   sx={{ p: 2 }}
        >
          <CardActionArea component={Link} to={"/wallets/USD"}>
            <CardContent>
              <Stack>
                <Box>
                  <Typography noWrap variant="h5">
                    USD
                  </Typography>
                </Box>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item md={3} sm={4} xs={6}>
        <Card
          component={"div"}
          elevation={1}
          variant="outlined"
          hover
          //   sx={{ p: 2 }}
        >
          <CardActionArea component={Link} to={"/wallets/EUR"}>
            <CardContent>
              <Stack>
                <Box>
                  <Typography noWrap variant="h5">
                    EUR
                  </Typography>
                </Box>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
};

export default WalletCurrencies;
