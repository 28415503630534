import * as React from "react";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MoreOutlined } from "@ant-design/icons";
import SelectUsers from "components/selectUsers/SelectUsers";
import { toast } from "react-toastify";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { useDispatch, useSelector } from "react-redux";
import { convertProspectEffect } from "store/effects/prospectEffects";
import NewTransaction from "../details/components/create/NewTransaction";

const ITEM_HEIGHT = 48;

export default function TransactionActions({
  transaction,
  handleSuccess,
  wallets,
  bankDetails,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [validating, setValidating] = React.useState(false);
  const [cancelling, setCancelling] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const {
    convert_propect_request,
    auth: { user },
  } = useSelector((state) => state.userAuth);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeStatus = (status) => {

    const id = toast.loading("Changing the status...");
    API.patch(URLS.TRANSACTIONS.EDIT(transaction.id), {status})
      .then((resp) => {
        const { data } = resp;
        toast.update(id, {
          render: `Transaction successfully market as ${String(status).replaceAll("_", " ")} .`,
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        handleSuccess();
      })
      .catch((error) => {
        toast.update(id, {
          render: error.response?.data?.detail ?? "Operation failed.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      });
  }

  const handleDeleteTransaction = () => {};

  const handleValidateTransaction = () => {
    const id = toast.loading("Validating the transaction...");
    API.put(URLS.TRANSACTIONS.VALIDATE(transaction.id))
      .then((resp) => {
        const { data } = resp;
        toast.update(id, {
          render: "Transaction successfully Validated.",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        handleSuccess();
      })
      .catch((error) => {
        toast.update(id, {
          render: error.response?.data?.detail ?? "Transaction validation failed.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      });
  };

  const handleCancelTransaction = () => {
    const id = toast.loading("Cancelling the transaction...");
    API.put(URLS.TRANSACTIONS.CANCEL(transaction.id))
      .then((resp) => {
        const { data } = resp;
        toast.update(id, {
          render: "Transaction successfully Cancelled.",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        handleSuccess();
      })
      .catch((error) => {
        toast.update(id, {
          render: error.response?.data?.detail ?? "Transaction rollback failed.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      });
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreOutlined />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            // width: "30ch",
          },
        }}
      >
        {user.is_superuser && (transaction.status != "VALIDATED" || transaction.status != "CANCELED") && (
          <>
            <MenuItem
              selected={false}
              onClick={() => handleChangeStatus("AWAITING_FOR_APPROVAL")}
              disabled={cancelling}
            >
              Mark as Awaiting for approval
            </MenuItem>
            <MenuItem
              selected={false}
              onClick={handleValidateTransaction}
              disabled={validating}
            >
              Mark as Approved
            </MenuItem>
            <MenuItem
              selected={false}
              onClick={() => handleChangeStatus("ON_HOLD")}
              disabled={validating}
            >
              Mark as On Hold
            </MenuItem>
            <MenuItem
              selected={false}
              onClick={() => handleChangeStatus("PENDING")}
              disabled={validating}
            >
              Mark as Pending
            </MenuItem>
            <MenuItem
              selected={false}
              onClick={handleCancelTransaction}
              disabled={cancelling}
            >
              Mark as Rejected
            </MenuItem>
          </>
        )}
        {transaction.status === "CANCELED" && (
          <MenuItem
          selected={false}
          onClick={handleValidateTransaction}
          disabled={validating}
        >
          Validate
        </MenuItem>
        )}
        <NewTransaction
          handleSuccess={handleSuccess}
          wallets={wallets}
          wallet={transaction}
          isEdition
          bankDetails={bankDetails}
          actionButton={() => <MenuItem selected={false}>Edit</MenuItem>}
        />

        {/* {user.is_superuser && (
          <MenuItem
            selected={false}
            onClick={handleDeleteTransaction}
            disabled={deleting}
            color="error"
          >
            Delete
          </MenuItem>
        )} */}

        {/* <MenuItem selected={false} onClick={handleClose}>
          Delete
        </MenuItem> */}
      </Menu>
    </div>
  );
}
