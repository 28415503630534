import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { API } from 'services/axios';
import { URLS } from 'services/urls/urls';
import {toast} from 'react-toastify'
import LoadingButton from "@mui/lab/LoadingButton";

export default function ResetPassword({user}) {
  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [resetingPW, setResetingPW] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleResetPassword = () => {
    setResetingPW(true);
    const data = {
        user: user.id,
        old_password: password,
        new_password: password,
    }
    API.post(URLS.AUTHS.resetPasswordAdmin, data)
      .then((resp) => {
        toast.success(`The user password has been changed to ${password}`);
        setResetingPW(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data.detail ?? "User password reset failed !")
        setResetingPW(false);
      });
  }

  return (
    <div>
      <Button variant="contained" color="error" onClick={handleClickOpen}>
        Reset password
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Password reset</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the new password of the user. Please donot forget to send it to the user.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="New password"
            type="text"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton loading={resetingPW} variant="contained" color="error" onClick={handleResetPassword}>Reset</LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
