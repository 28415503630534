import {
  Grid,
  Select,
  Typography,
  Stack,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Fade,
  InputLabel,
  FormControl,
  Pagination,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  TextField,
  Paper,
  Collapse,
  TablePagination,
} from "@mui/material";

import PropTypes from "prop-types";
import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import moment, { isMoment } from "moment";
import { MoreOutlined } from "@ant-design/icons";
import { LinearProgress } from "@mui/material";
import "pages/users/details/components/styles.css";
import "pages/users/details/components/bootstrap.css";

// Icons
import {
  DeleteOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import NewWallet from "pages/users/details/components/create/NewWallet";
import CopyButton from "components/@extended/CopyButton";

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{}} hover>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id} {" "}
                        <CopyButton text={row.id} title={"Copy the wallet id"} />
        </TableCell>
        <TableCell>
          {row?.user ? (
            <Link to={`/users/${row?.user?.id}/details`}>
              {row.user?.first_name + " " + row.user?.last_name}
            </Link>
          ) : (
            <div />
          )}
        </TableCell>
        <TableCell>{row.balance ?? "0" + " " + row.currency}</TableCell>
        <TableCell>{row.address} {" "}
                        <CopyButton text={row.address} title={"Copy the address"} /></TableCell>
        {/* <TableCell>{row.protein}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Reference</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.transactions?.map((historyRow) => (
                    <TableRow key={historyRow.id}>
                      <TableCell component="th" scope="row">
                        <Link to={`#`}>{historyRow?.reference} {" "}
                        <CopyButton text={historyRow?.reference} title={"Copy the reference"} /></Link>
                      </TableCell>
                      <TableCell>{historyRow?.amount}</TableCell>
                      <TableCell>{String(historyRow?.status).replaceAll("_", " ")}</TableCell>
                      <TableCell>
                        {moment(historyRow?.date).format("lll")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

// ==============================|| PROSPECTS LIST ||============================== //

function OrderTableHead({ handleSelectAll, isAllSelected }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          {" "}
          <Checkbox
            checked={isAllSelected}
            onClick={() => handleSelectAll(isAllSelected)}
          />
        </TableCell>
        <TableCell>Reference</TableCell>
        <TableCell>Amount</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Date</TableCell>
      </TableRow>
    </TableHead>
  );
}

OrderTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

export default function AdminWallets() {
  const { userId } = useParams();
  const [wallets, setWallets] = useState({
    results: [],
    total: 0,
    page_size: 25,
  });

  const [loading, setLoading] = useState(true);
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = useState("");
  const [selectedWallet, setSelectedWallet] = useState();
  const [attribuedStatus, setAttribuedStatus] = useState("ATTRIBUTED");

  const { currency } = useParams();

  const handleSuccess = (wallet) => {
    API.get(
      attribuedStatus === "ATTRIBUTED"
        ? URLS.WALLETS.LIST(search, page, currency)
        : URLS.WALLETS.LIST_NOT_ATTRIBUED(search, page, currency)
    )
      .then((resp) => {
        setWallets(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleChangePage = (event, value) => {
    setPage(value + 1);
    // console.log(value)
  };

  const handleChangeAttribuedStatus = (event) => {
    setAttribuedStatus(event.target.value);
  };

  useEffect(() => {
    setLoading(true);
    API.get(
      attribuedStatus === "ATTRIBUTED"
        ? URLS.WALLETS.LIST(search, page, currency)
        : URLS.WALLETS.LIST_NOT_ATTRIBUED(search, page, currency)
    )
      .then((resp) => {
        setWallets(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [search, page, attribuedStatus]);

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Stack justifyContent="end">
          <NewWallet
            handleSuccess={handleSuccess}
            actionButton={() => (
              <Button
                variant="contained"
                sx={{ width: "200px", marginLeft: "auto" }}
                color="primary"
              >
                New wallet
              </Button>
            )}
          />
        </Stack>
      </Grid>
      <Grid item md={12}>
        <Grid>{loading && <LinearProgress />}</Grid>
      </Grid>
      <Grid item md={6} p={3}>
        <Grid>
          <Grid container spacing={3}>
            <Grid item md={8} xs={12}>
              <Grid>
                <Box>
                  <TextField
                    fullWidth
                    placeholder="Search with currency, balance, owner email, etc..."
                    onChange={(event) => setSearch(event.target.value)}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={attribuedStatus}
                    label="Age"
                    onChange={handleChangeAttribuedStatus}
                  >
                    <MenuItem value={"ATTRIBUTED"}>ATTRIBUTED</MenuItem>
                    <MenuItem value={"NOT_ATTRIBUTED"}>NOT ATTRIBUTED</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <div class="crypt-deepblue-segment p-2">
            {!loading && wallets?.results?.length === 0 && (
              <Typography sx={{ textAlign: "left" }} variant="body2">
                No Wallet found.
              </Typography>
            )}
            {/* <form class="crypt-dash-search">
                          <input type="search" placeholder="Search..." name="s" class="crypt-big-search"/>
                          <button type="submit">
                              <i class="pe-7s-search"></i>
                          </button>
                      </form> */}
            {/* <ul class="crypt-big-list crypt-coin-select">
              {wallets?.results.map((wallet) => {
                return (
                  <li key={wallet.id}>
                    <a
                      href="#bitcoin"
                      className={
                        selectedWallet?.id === wallet.id ? "active" : ""
                      }
                      onClick={(event) => {
                        event.preventDefault();
                        setSelectedWallet(wallet);
                      }}
                    >
                      <img
                        src={`/assets/img/${wallet?.currency?.toLowerCase()}.png`}
                        height="25"
                        className="crypt-market-cap-logo pr-2"
                        alt="coin"
                      />{" "}
                      {wallet?.currency}{" "}
                      <p className="fright">
                        <b>{wallet.balance}</b>
                      </p>
                    </a>
                  </li>
                );
              })}
            </ul> */}
          </div>
          {/* {wallets?.results.length > 0 && (
            <Stack mt={4} spacing={2}>
              <Pagination
                onChange={handleChangePage}
                count={
                  Math.round(wallets.total / wallets.page_size) === 0
                    ? 1
                    : Math.round(wallets.total / wallets.page_size)
                }
                color="primary"
              />
            </Stack>
          )} */}
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Grid>
          {!loading && wallets?.results?.length && (
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>ID</TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell>Balance</TableCell>
                    <TableCell>Wallet address</TableCell>
                    {/* <TableCell>Protein&nbsp;(g)</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {wallets?.results.map((row) => (
                    <Row key={row.name} row={row} />
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[25]}
                component="div"
                count={wallets?.total}
                rowsPerPage={25}
                page={wallets?.page - 1}
                onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Grid>
      </Grid>
      <Grid item md={6} p={3}>
        {selectedWallet && (
          <Grid>
            <Typography variant="h3">Wallet transactions</Typography>
            <Box>
              {selectedWallet && (
                <TableContainer
                  sx={{
                    width: "100%",
                    overflowX: "auto",
                    position: "relative",
                    display: "block",
                    maxWidth: "100%",
                    "& td, & th": { whiteSpace: "nowrap" },
                  }}
                >
                  <Table
                    aria-labelledby="tableTitle"
                    sx={{
                      "& .MuiTableCell-root:first-child": {
                        pl: 2,
                      },
                      "& .MuiTableCell-root:last-child": {
                        pr: 3,
                      },
                    }}
                  >
                    <OrderTableHead
                      handleSelectAll={() => {}}
                      isAllSelected={false}
                    />
                    <TableBody>
                      {selectedWallet?.transactions?.map((row1, index) => {
                        const row = { ...row1 };
                        const labelId = `enhanced-table-checkbox-${row.id}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            tabIndex={-1}
                            key={row.id}
                          >
                            <TableCell>
                              {" "}
                              {row1.type === "CREDIT" ? (
                                <ArrowDownOutlined
                                  style={{ fontSize: "30px", color: "#56F000" }}
                                  rotate={-30}
                                />
                              ) : (
                                <ArrowUpOutlined
                                  style={{ fontSize: "30px", color: "#FF3838" }}
                                  rotate={-30}
                                />
                              )}
                            </TableCell>
                            <TableCell align="left">{row.reference} {" "}
                        <CopyButton text={row.reference} title={"Copy the reference"} /></TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              align="left"
                            >
                              {row.amount ?? 0} {row.wallet?.currency}
                            </TableCell>
                            <TableCell align="left">
                              {row.status === "PENDING" ? (
                                <span style={{ color: "#FFB302" }}>
                                  {row.status}
                                </span>
                              ) : row.status === "VALIDATED" ? (
                                <span style={{ color: "#56F000" }}>
                                  {row.status}
                                </span>
                              ) : (
                                <span style={{ color: "#FF3838 " }}>
                                  {row.status}
                                </span>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {moment(row.date).format("lll")}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  {/* <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={count}
              rowsPerPage={pageSize}
              page={page1 - 1 ?? 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />*/}
                  {selectedWallet?.transactions?.length === 0 && (
                    <Typography sx={{ textAlign: "center" }} variant="body2">
                      No transaction for this wallet
                    </Typography>
                  )}
                </TableContainer>
              )}
            </Box>

            {/* <div class="crypt-dash-withdraw mt-3 d-block" id="bitcoin">
              <div class="crypt-withdraw-heading border">
                <div class="row">
                  <div class="col-md-3">
                    <p>
                      <b>1 BTC</b>
                    </p>
                    <p class="crypt-up">
                      <b>$3,483.59 USDT (0.28%) </b>
                    </p>
                  </div>
                  <div class="col-md-5">
                    <i class="pe-7s-lock icon-big"></i>
                    <p>
                      <b>Locked: $ 0.00</b>
                    </p>
                  </div>
                  <div class="col-md-4">
                    <p>
                      <b>Total:</b>
                    </p>
                    <p class="crypt-up">
                      <b> $ 23454.00</b>
                    </p>
                  </div>
                </div>
              </div>
              <div class="crypt-withdraw-body bg-white">
                <div class="row">
                  <div class="col-md-3">
                    <div
                      class="nav flex-column nav-pills"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <a
                        class="nav-link active"
                        id="v-pills-zilliqua-btc-deposit-tab"
                        data-toggle="pill"
                        href="#v-pills-zilliqua-btc-deposit"
                        role="tab"
                        aria-controls="v-pills-zilliqua-btc-deposit"
                        aria-selected="true"
                      >
                        <i class="pe-7s-bottom-arrow"></i> Deposit
                      </a>
  
                      <a
                        class="nav-link"
                        id="v-pills-zilliqua-btc-withdrawl-tab"
                        data-toggle="pill"
                        href="#v-pills-zilliqua-btc-withdrawl"
                        role="tab"
                        aria-controls="v-pills-zilliqua-btc-withdrawl"
                        aria-selected="false"
                      >
                        <i class="pe-7s-up-arrow"></i> Withdraw
                      </a>
  
                      <a
                        class="nav-link"
                        id="v-pills-zilliqua-btc-history-tab"
                        data-toggle="pill"
                        href="#v-pills-zilliqua-btc-history"
                        role="tab"
                        aria-controls="v-pills-zilliqua-btc-history"
                        aria-selected="false"
                      >
                        <i class="pe-7s-clock"></i> History
                      </a>
  
                      <a
                        class="nav-link"
                        id="v-pills-zilliqua-btc-buysell-tab"
                        data-toggle="pill"
                        href="#v-pills-zilliqua-btc-buysell"
                        role="tab"
                        aria-controls="v-pills-zilliqua-btc-buysell"
                        aria-selected="false"
                      >
                        <i class="pe-7s-refresh-2"></i> Buy / Sell
                      </a>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="tab-content" id="v-pills-zilliqua-btc-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="v-pills-zilliqua-btc-deposit"
                        role="tabpanel"
                        aria-labelledby="v-pills-zilliqua-btc-deposit-tab"
                      >
                        <h6 class="text-uppercase">REMAINING TO VIP $3000</h6>
                        <p>
                          Details -{" "}
                          <a href="" class="crypt-up">
                            Get VIP Now
                          </a>
                        </p>
                        <form class="deposit-form">
                          <div class="crypt-radio-boxed">
                            <input
                              type="radio"
                              name="payment-amount"
                              id="payment-btc-amount1"
                              class="payment-amount"
                            />
                            <label for="payment-btc-amount1">
                              $ 30000{" "}
                              <img src="images/vip.svg" width="40" alt="" />
                            </label>
                            <div class="check"></div>
                          </div>
                          <div class="crypt-radio-boxed">
                            <input
                              type="radio"
                              name="payment-amount"
                              id="payment-btc-amount2"
                              class="payment-amount"
                            />
                            <label for="payment-btc-amount2">
                              $ 20000{" "}
                              <img src="images/vip.svg" width="40" alt="" />
                            </label>
                            <div class="check"></div>
                          </div>
                          <div class="crypt-radio-boxed">
                            <input
                              type="radio"
                              name="payment-amount"
                              id="payment-btc-amount3"
                              class="payment-amount"
                            />
                            <label for="payment-btc-amount3">
                              $ 10000{" "}
                              <img src="images/vip.svg" width="40" alt="" />
                            </label>
                            <div class="check"></div>
                          </div>
                          <div class="crypt-radio-boxed">
                            <input
                              type="radio"
                              name="payment-amount"
                              id="payment-btc-amount4"
                              class="payment-amount"
                            />
                            <label for="payment-btc-amount4">$ 5000 </label>
                            <div class="check"></div>
                          </div>
                          <div class="crypt-radio-boxed">
                            <input
                              type="radio"
                              name="payment-amount"
                              id="payment-btc-amount5"
                              class="payment-amount"
                            />
                            <label for="payment-btc-amount5">$ 2000 </label>
                            <div class="check"></div>
                          </div>
                          <div class="crypt-radio-boxed">
                            <input
                              type="radio"
                              name="payment-amount"
                              id="payment-btc-amount6"
                              class="payment-amount"
                            />
                            <label for="payment-btc-amount6">$ 1000 </label>
                            <div class="check"></div>
                          </div>
                          <div class="form-group mt-2">
                            <select class="crypt-image-select" required="">
                              <option value="">Choose A Payment Option</option>
                              <option value="1">Visa</option>
                              <option value="2">Master Card</option>
                              <option value="3">AMEX</option>
                            </select>
                          </div>
                          <div class="input-group input-text-select mb-3">
                            <div class="input-group-prepend">
                              <input
                                placeholder="Amount"
                                type="text"
                                class="form-control crypt-input-lg"
                              />
                            </div>
                            <select
                              class="custom-select"
                              name="inputGroupSelect01"
                            >
                              <option value="1">USD</option>
                              <option value="2">GBP</option>
                              <option value="3">EUR</option>
                            </select>
                          </div>
                          <div class="text-center crypt-up mt-5 mb-5">
                            <p>You will approximately pay</p>
                            <h3>$500</h3>
                          </div>
                          <a href="" class="crypt-button-red-full">
                            Proceed To Payment
                          </a>
                        </form>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="v-pills-zilliqua-btc-withdrawl"
                        role="tabpanel"
                        aria-labelledby="v-pills-zilliqua-btc-withdrawl-tab"
                      >
                        <h4 class="crypt-down">Wire bank transfer</h4>
                        <p>
                          <i class="pe-7s-info"></i> Standard bank transfer will
                          be made up to 2 workdays
                        </p>
                        <form>
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              placeholder="Amount"
                              class="form-control"
                              name="amount"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text">USD</span>
                            </div>
                          </div>
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              placeholder="Bank Account Number"
                              class="form-control"
                              name="bank-account"
                            />
                            <div class="input-group-append">
                              <button
                                class="btn btn-outline-secondary dropdown-toggle"
                                type="button"
                                data-toggle="dropdown"
                              >
                                Other Address
                              </button>
                              <div class="dropdown-menu">
                                <a class="dropdown-item" href="#">
                                  234235234
                                </a>
                                <a class="dropdown-item" href="#">
                                  2343453453
                                </a>
                                <a class="dropdown-item" href="#">
                                  234234234234
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Name"
                              name="name"
                            />
                          </div>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Swift Code"
                              name="swift"
                            />
                          </div>
                          <div class="form-group">
                            <div class="form-group">
                              <select class="form-control">
                                <option>Country</option>
                                <option>United States</option>
                                <option>India</option>
                                <option>Japan</option>
                                <option>Korea</option>
                                <option>China</option>
                              </select>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="recipient-btc"
                              />
                              <label class="form-check-label" for="recipient-btc">
                                Add To recipient
                              </label>
                            </div>
                          </div>
                          <a href="" class="crypt-button-red-full">
                            Initiate Withdraw
                          </a>
                        </form>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="v-pills-zilliqua-btc-history"
                        role="tabpanel"
                        aria-labelledby="v-pills-zilliqua-btc-history-tab"
                      >
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Time</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Currency</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">22:35:59</th>
                              <td class="crypt-down">0.000056</td>
                              <td>BTC</td>
                            </tr>
                            <tr>
                              <th scope="row">22:35:59</th>
                              <td>0.0000564</td>
                              <td>ETH</td>
                            </tr>
                            <tr>
                              <th scope="row">22:35:59</th>
                              <td>0.0000234</td>
                              <td>XHO</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="v-pills-zilliqua-btc-buysell"
                        role="tabpanel"
                        aria-labelledby="v-pills-zilliqua-btc-buysell-tab"
                      >
                        <div class="crypt-boxed-area">
                          <h6 class="crypt-bg-head">
                            <b class="crypt-up">BUY</b> /{" "}
                            <b class="crypt-down">SELL</b>
                          </h6>
                          <div class="row no-gutters">
                            <div class="col-md-12 col-xxl-6">
                              <div class="crypt-buy-sell-form">
                                <p>
                                  Buy <span class="crypt-up">BTC</span>{" "}
                                  <span class="fright">
                                    Available: <b class="crypt-up">20 BTC</b>
                                  </span>
                                </p>
                                <div class="crypt-buy">
                                  <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Price</span>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="0.02323476"
                                      readonly=""
                                    />
                                    <div class="input-group-append">
                                      <span class="input-group-text">BTC</span>
                                    </div>
                                  </div>
                                  <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Amount</span>
                                    </div>
                                    <input type="number" class="form-control" />
                                    <div class="input-group-append">
                                      <span class="input-group-text">BTC</span>
                                    </div>
                                  </div>
                                  <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Total</span>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      readonly=""
                                    />
                                    <div class="input-group-append">
                                      <span class="input-group-text">BTC</span>
                                    </div>
                                  </div>
                                  <div>
                                    <p>
                                      Fee:{" "}
                                      <span class="fright">100%x0.2 = 0.02</span>
                                    </p>
                                  </div>
                                  <div class="text-center mt-5 mb-5 crypt-up">
                                    <p>You will approximately pay</p>
                                    <h4>0.09834 BTC</h4>
                                  </div>
                                  <div class="menu-green">
                                    <a href="" class="crypt-button-green-full">
                                      Buy
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 col-xxl-6">
                              <div class="crypt-buy-sell-form">
                                <p>
                                  Sell <span class="crypt-down">BTC</span>{" "}
                                  <span class="fright">
                                    Available: <b class="crypt-down">20 BTC</b>
                                  </span>
                                </p>
                                <div class="crypt-sell">
                                  <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Price</span>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="0.02323476"
                                      readonly=""
                                    />
                                    <div class="input-group-append">
                                      <span class="input-group-text">BTC</span>
                                    </div>
                                  </div>
                                  <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Amount</span>
                                    </div>
                                    <input type="number" class="form-control" />
                                    <div class="input-group-append">
                                      <span class="input-group-text">BTC</span>
                                    </div>
                                  </div>
                                  <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Total</span>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      readonly=""
                                    />
                                    <div class="input-group-append">
                                      <span class="input-group-text">BTC</span>
                                    </div>
                                  </div>
                                  <div>
                                    <p>
                                      Fee:{" "}
                                      <span class="fright">100%x0.2 = 0.02</span>
                                    </p>
                                  </div>
                                  <div class="text-center mt-5 mb-5 crypt-down">
                                    <p>You will approximately pay</p>
                                    <h4>0.09834 BTC</h4>
                                  </div>
                                  <div>
                                    <a href="" class="crypt-button-red-full">
                                      Sell
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </Grid>
        )}
      </Grid>
      {/* {wallets.map((wallet) => {
          return (
            <Grid key={wallet.id} item md={4}>
              <Card
                variant="outlined"
                sx={{
                  p: 2,
                  backgroundColor: "#000",
                  backgroundImage: `url(${
                    wallet.currency === "BTC"
                      ? "/assets/img/BTC.jpg"
                      : wallet.currency === "USD"
                      ? "/assets/img/USD.jpg"
                      : wallet.currency === "USDT"
                      ? "/assets/img/USDT.png"
                      : wallet.currency === "ETH"
                      ? "/assets/img/ETH.png"
                      : "/assets/img/EUR.png"
                  })`,
                  height: 250,
                  backgroundSize: "30%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top right",
                  borderRadius: "1rem",
                  position: "relative",
                }}
                rounded
              >
                <WalletMenu wallet={wallet} handleSuccess={handleSuccess} />
                <Stack>
                  <img
                    height={"100px"}
                    width={"100px"}
                    src={URLS.BASE_URL + wallet.qr_code}
                    alt="qr code"
                  />
                  <div style={{ marginTop: "1rem" }}></div>
                  <Typography variant="h6" sx={{ color: "#fff" }}>
                    {wallet.address}
                  </Typography>
                  <Typography variant="h4" sx={{ color: "#fff" }}>
                    {wallet.currency}
                  </Typography>
                  <Typography variant="h3" sx={{ color: "#fff" }}>
                    {wallet.balance} {}
                  </Typography>
                  <br />
                  <Typography variant="body1" sx={{ color: "#fff" }}>
                    {moment(wallet.created_at).format("MMMM Do YYYY, h:mm:ss a")}
                  </Typography>
                </Stack>
              </Card>
            </Grid>
          );
        })} */}
    </Grid>
  );
}
