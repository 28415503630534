// assets
import { DashboardOutlined, UserOutlined, MailOutlined, UsergroupAddOutlined, WalletOutlined, BookOutlined, } from '@ant-design/icons';

// icons
const icons = {
    DashboardOutlined,
    UserOutlined,
    MailOutlined,
    UsergroupAddOutlined,
    WalletOutlined,
    BookOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'group-dashboard',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.DashboardOutlined,
            breadcrumbs: false
        },
        {
            id: 'usersl-list',
            title: 'Users List',
            type: 'item',
            url: '/users/list',
            icon: icons.UsergroupAddOutlined,
            breadcrumbs: false
        },
        // {
        //     id: 'repports-list',
        //     title: 'Reports',
        //     type: 'item',
        //     url: '/reports/list',
        //     icon: icons.BookOutlined,
        //     breadcrumbs: true
        // },
        // {
        //     id: 'deposit-details',
        //     title: 'Deposit Info',
        //     type: 'item',
        //     url: '/deposit-details',
        //     icon: icons.WalletOutlined,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'messages',
        //     title: 'Messages',
        //     type: 'item',
        //     url: '/messages',
        //     icon: icons.MailOutlined,
        //     breadcrumbs: false
        // }
    ]
};

export default dashboard;
