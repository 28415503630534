import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Grid,
  Card,
  Stack,
  TextField,
  Button,
  MenuItem,
  CardContent,
  Box,
  Typography,
  LinearProgress,
  CardActions,
  IconButton,
  Pagination,
} from "@mui/material";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import Swal from "sweetalert2";
import { useParams } from "react-router";
import moment from "moment";
import { useSelector } from "react-redux";

function CallRequests() {
  const [reports, setReports] = useState({
    results: [],
    total: 0,
    page_size: 25,
    pages: 1,
    page: 1,
  });

  const { userId } = useParams();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [severity, setSeverity] = useState("ALL");

  const {
    auth: { user },
  } = useSelector((state) => state.userAuth);

  const handleChangePage = (event, value) => {
    setPage(value);
    // console.log(value)
  };

  const handleFilter = () => {
    // console.log(date, page, search)
    setLoading(true);
    API.get(
      userId
        ? URLS.CALL_REQUEST.user(userId, search, page, severity)
        : URLS.CALL_REQUEST.list(search, page, severity)
    )
      .then((resp) => {
        setLoading(false);
        const { data } = resp;
        if (userId) {
          setReports({
            results: data,
          });
        } else {
          setReports(data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setLoading(false);
      });
  };

  const handleDeleteReport = (report) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire("Deleted!", "Your Report has been deleted.", "success");
        // toastId.current = toast("Deleting...", {
        //   autoClose: false,
        //   closeButton: true,
        // });

        const idToast = toast.loading("We are deleting the report...");
        API.delete(URLS.REPORTS.DELETE(report.id))
          .then((resp) => {
            toast.update(idToast, {
              render: "The Report has been deleted.",
              type: "success",
              isLoading: false,
              autoClose: 3000,
            });
            handleFilter();
            // Swal.fire("Deleted!", "The Report has been deleted.", "success");
          })
          .catch((error) => {
            toast.update(idToast, {
              render:
                error.response.data.detail ??
                "Report deletion error!! Please try later",
              type: "error",
              isLoading: false,
              autoClose: 3000,
            });
          });
      }
    });
  };

  useEffect(() => {
    API.get(
      userId
        ? URLS.CALL_REQUEST.user(userId, search, page, severity)
        : URLS.CALL_REQUEST.list(search, page, severity)
    )
      .then((resp) => {
        setLoading(false);
        const { data } = resp;
        if (userId) {
          setReports({
            results: data,
          });
        } else {
          setReports(data);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [page]);

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Stack spacing={3} direction={"row"} justifyContent={"space-between"}>
          <Stack direction="row" spacing={3}>
            <TextField
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              placeholder="Search by call request subject, user id, content, etc..."
            />
            <TextField
              value={severity}
              onChange={(event) => setSeverity(event.target.value)}
              label="Severity"
              select
              sx={{ minWidth: "200px" }}
            >
              <MenuItem value={"ALL"}>ALL</MenuItem>
              <MenuItem value={"NORMAL"}>NORMAL</MenuItem>
              <MenuItem value={"URGENT"}>URGENT</MenuItem>
              <MenuItem value={"VERY_URGENT"}>VERY URGENT</MenuItem>
            </TextField>
            {/* <TextField
              placeholder="Date"
              value={date}
              onChange={(event) => setDate(event.target.value)}
              type="date"
            /> */}
            <Button variant={"contained"} onClick={handleFilter}>
              Search
            </Button>
          </Stack>
          {/* <NewReport
            handleSuccess={handleFilter}
            actionButton={() => (
              <Button variant={"contained"}>New Report</Button>
            )}
          /> */}
        </Stack>
      </Grid>
      <Grid item md={12}>
        <Grid>{loading && <LinearProgress />}</Grid>
      </Grid>
      {reports?.results?.map((report, index) => {
        return (
          <Grid item md={3} sm={4} xs={12} key={"rt" + report.id}>
            <Card
              component={"div"}
              elevation={1}
              variant="outlined"
              //   sx={{ p: 2 }}
            >
              <CardContent>
                <Stack>
                  <Box>
                    <Typography noWrap variant="h5">
                      {report.subject}
                    </Typography>

                    <Box>
                      {/* <Badge
                        badgeContent={"SEVERITY"}
                        overlap={"rectangular"}
                        color={index % 2 === 0 ? "success" : "error"}
                        sx={{
                          "& .MuiBadge-badge": {
                            transform: "translate(100%, 0%)",
                            borderRadius: "3px",
                          },
                        }}
                      ></Badge> */}
                    </Box>
                  </Box>
                  <Box></Box>
                </Stack>
              </CardContent>
              <CardActions sx={{ px: 3 }}>
                <Grid container spacing={3}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Stack direction={{ md: "row", xs: "column" }} spacing={2}>
                      {/* <Tooltip mt={2} title="Report creator">
                        <Avatar
                          sx={{ width: 30, height: 30 }}
                          alt="Remy Sharp"
                          src="https://mui.com/static/images/avatar/1.jpg"
                        />
                      </Tooltip> */}
                      <Stack direction="column">
                        {!userId && (
                          <Typography>
                            {report?.user?.first_name
                              ? report?.user?.first_name[0] + ". "
                              : ""}
                            {report?.user?.last_name
                              ? report?.user?.last_name
                              : ""}
                          </Typography>
                        )}
                        <Typography variant="caption" color="default">
                          Call date:{" "}
                          {moment(report?.call_date).format("lll") ?? ""}
                        </Typography>
                        <Typography color="error">
                          {report?.severity}
                        </Typography>
                        <Typography
                          sx={{ color: "gray" }}
                          mt={0}
                          variant="caption"
                          color="default"
                        >
                          {moment(report.created_at).fromNow()}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>

                  {1 === 2 && (
                    <Grid hidden item md={6} sm={6} xs={12}>
                      <Stack spacing={2} direction="row">
                        {/* <NewReport
                        isEdition
                        handleSuccess={handleFilter}
                        toEdit={report}
                        actionButton={() => (
                          <IconButton>
                            <EditOutlined />
                          </IconButton>
                        )}
                      /> */}

                        {/* <ReportDetails
                        report={report}
                        actionButton={() => (
                          <IconButton>
                            <EyeOutlined />
                          </IconButton>
                        )}
                      /> */}

                        {(user?.is_team_manager || user?.is_superuser) && (
                          <IconButton
                            onClick={() => handleDeleteReport(report)}
                          >
                            <DeleteOutlined />
                          </IconButton>
                        )}
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        );
      })}
      {!loading && reports?.results.length === 0 && (
        <Grid item md={12}>
          <Grid>
            <Typography variant="body">
              No Call request found {userId ? "for this user" : ""}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid item md={12} mt={3} mb={3}>
        {!loading && reports?.total > 0 && (
          <Pagination
            page={reports?.page}
            count={reports?.pages}
            color="primary"
            onChange={(event, page) => {
              setPage(page);
              console.log("page", page);
            }}
          />
        )}
      </Grid>
      {/* <Grid item md={12}>
        {!loading && reports?.results.length >= 0 && (
          <Stack mt={4} spacing={2}>
            <Pagination
              onChange={handleChangePage}
              count={
                Math.round(reports.total / reports.page_size) === 0
                  ? 1
                  : Math.round(reports.total / reports.page_size)
              }
              color="primary"
            />
          </Stack>
        )}
      </Grid> */}
    </Grid>
  );
}

export default CallRequests;
