import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";

import Typography from "@mui/material/Typography";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function NewBankDepositDetails({
  onSuccess,
  isEdition,
  toEdit,
}) {
  const [open, setOpen] = React.useState(false);
  const [creating, setCreating] = React.useState(false);

  const {
    auth: { user },
  } = useSelector((state) => state.userAuth);

  const [accountName, setAccountName] = React.useState(
    isEdition ? toEdit?.account_name : ""
  );
  const [accountIban, setAccountIban] = React.useState(
    isEdition ? toEdit?.iban : ""
  );
  const [accountSwift, setAccountSwift] = React.useState(
    isEdition ? toEdit?.swift : ""
  );

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCreate = () => {
    if (accountName && accountSwift && accountIban) {
      setCreating(true);
      const data = {
        iban: accountIban,
        account_name: accountName,
        swift: accountSwift,
        created_by: user.id,
      };
      if (isEdition) {
        API.patch(URLS.WALLETS.DEPOSITS.BANK_EDIT(toEdit?.id), data)
          .then((resp) => {
            onSuccess(resp.data, true);
            handleClose();
            toast.success(`Bank deposit details successfuly ${isEdition ? "edited" : "added"}.`);
          })
          .catch((error) => {
            setCreating(false);
            toast.error(
              error?.response?.data?.detail ?? `Bank details ${isEdition ? "editing" : "creating"} error.`
            );
          });
      } else {
        API.post(URLS.WALLETS.DEPOSITS.BANK, data)
          .then((resp) => {
            onSuccess(resp.data);
            handleClose();
            toast.success("Bank deposit details successfuly added.");
          })
          .catch((error) => {
            setCreating(false);
            toast.error(
              error?.response?.data?.detail ?? "Bank details creating error."
            );
          });
      }
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {isEdition ? (
        <IconButton
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
        >
          <EditOutlined />
        </IconButton>
      ) : (
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Create new
        </Button>
      )}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={"sm"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Bank deposit informations
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12}>
              <TextField
                sx={{ width: "100%" }}
                id="outlined-account-name"
                label="Account name"
                value={accountName}
                onChange={(event) => setAccountName(event.target.value)}
                variant="outlined"
                size="md"
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <Grid>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="Account swift"
                  variant="outlined"
                  value={accountSwift}
                  onChange={(event) => setAccountSwift(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid item md={6} sm={12}>
              <Grid>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="Account iban"
                  variant="outlined"
                  value={accountIban}
                  onChange={(event) => setAccountIban(event.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={creating}
            variant="contained"
            color={"primary"}
            onClick={handleCreate}
          >
            {isEdition ? "Save changes" : "Create"}
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
