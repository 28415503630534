// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    loading_contacts_request: false,
    loading_contacts_success: false,
    loading_contacts_failed: false,
    contactsList: {
        results: []
    }
};

// ==============================|| SLICE - contacts ||============================== //

const contacts = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        loadingcontactsRequest(state, action) {
            state.loading_contacts_request = true;
            state.loading_contacts_success = false;
            state.loading_contacts_failed = false;
        },

        loadingcontactsSuccess(state, action) {
            state.loading_contacts_request = false;
            state.loading_contacts_success = true;
            state.loading_contacts_failed = false;
            state.contactsList = action.payload;
        },
        loadingcontactsFailed(state, action) {
            state.loading_contacts_request = false;
            state.loading_contacts_success = false;
            state.loading_contacts_failed = false;
        }
    }
});

export default contacts.reducer;

export const { loadingcontactsRequest, loadingcontactsSuccess, loadingcontactsFailed } = contacts.actions;
