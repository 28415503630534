import { Children } from "react";
import { useDispatch } from "react-redux";
import SecureLS from "secure-ls";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { loginUserSuccess } from "store/reducers/userAuth";

let ls = new SecureLS({ encodingType: "aes" });

export default function Auth({ children }) {
  const user = ls.get("user-details");
  const dispatch = useDispatch();
  if (user) {
      dispatch(loginUserSuccess(user));
    //   API.get(URLS.USERS.ME)
    //     .then((resp) => {
    //       dispatch(loginUserSuccess(resp.data));
    //     })
    //     .catch((error) => {
    //   });
  }

  return children;
}
