// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    create_propect_request: false,
    create_propect_success: false,
    create_propect_failed: false,
    list_propect_request: false,
    list_propect_success: false,
    list_propect_failed: false,
    edit_propect_request: false,
    edit_propect_success: false,
    edit_propect_failed: false,
    convert_propect_request: false,
    convert_propect_success: false,
    convert_propect_failed: false,
    prospectsList: {
        results: []
    }
};

// ==============================|| SLICE - PROSPECTS ||============================== //

const prospects = createSlice({
    name: 'prospects',
    initialState,
    reducers: {
        createProspectRequest(state, action) {
            state.create_propect_request = true;
            state.create_propect_success = false;
            state.create_propect_failed = false;
        },
        createProspectSuccess(state, action) {
            state.create_propect_request = false;
            state.create_propect_success = true;
            state.create_propect_failed = false;
            state.prospectsList.results = state.prospectsList.results.concat(action.payload);
        },
        createProspectFailed(state, action) {
            state.create_propect_request = false;
            state.create_propect_success = false;
            state.create_propect_failed = true;
        },
        // List prospects
        listProspectRequest(state, action) {
            state.list_propect_request = true;
            state.list_propect_success = false;
            state.list_propect_failed = false;
        },
        listProspectSuccess(state, action) {
            state.list_propect_request = false;
            state.list_propect_success = true;
            state.list_propect_failed = false;
            state.prospectsList = action.payload;
        },
        listProspectFailed(state, action) {
            state.list_propect_request = false;
            state.list_propect_success = false;
            state.list_propect_failed = true;
        },
        // Edit prospects
        editProspectRequest(state, action) {
            state.edit_propect_request = true;
            state.edit_propect_success = false;
            state.edit_propect_failed = false;
        },
        editProspectSuccess(state, action) {
            state.edit_propect_request = false;
            state.edit_propect_success = true;
            state.edit_propect_failed = false;
            state.prospectsList.results = state.prospectsList.results.map((prospect) => {
                if (prospect.id === action.payload.id) {
                    prospect = action.payload;
                }
                return prospect;
            });
        },
        editProspectFailed(state, action) {
            state.edit_propect_request = false;
            state.edit_propect_success = false;
            state.edit_propect_failed = true;
        },
        // set selected pospect
        setSelectedProspect(state, action) {
            state.selectedProspect = action.payload;
        },
        // Convert prospects
        convertProspectRequest(state, action) {
            state.convert_propect_request = true;
            state.convert_propect_success = false;
            state.convert_propect_failed = false;
        },
        convertProspectSuccess(state, action) {
            state.convert_propect_request = false;
            state.convert_propect_success = true;
            state.convert_propect_failed = false;
            // state.prospectsList.results = state.prospectsList.results.map((prospect) => {
            //     if (prospect.id === action.payload.id) {
            //         prospect.is_converted = true;
            //     }

            //     return prospect;
            // });
            state.prospectsList.results = state.prospectsList.results.filter((prospect) => prospect.id != action.payload.id);
        },
        convertProspectFailed(state, action) {
            state.convert_propect_request = false;
            state.convert_propect_success = false;
            state.convert_propect_failed = true;
        },
        // Delete prospects
        deleteProspectRequest(state, action) {
            state.delete_propect_request = true;
            state.delete_propect_success = false;
            state.delete_propect_failed = false;
        },
        deleteProspectSuccess(state, action) {
            state.delete_propect_request = false;
            state.delete_propect_success = true;
            state.convert_propect_failed = false;
            state.prospectsList.results = state.prospectsList.results.filter((prospect) => prospect.id !== action.payload.id);
        },
        deleteProspectFailed(state, action) {
            state.convert_propect_request = false;
            state.convert_propect_success = false;
            state.convert_propect_failed = true;
        }
    }
});

export default prospects.reducer;

export const {
    createProspectRequest,
    createProspectSuccess,
    createProspectFailed,
    listProspectRequest,
    listProspectSuccess,
    listProspectFailed,
    editProspectRequest,
    editProspectSuccess,
    editProspectFailed,
    setSelectedProspect,
    convertProspectRequest,
    convertProspectSuccess,
    convertProspectFailed,
    deleteProspectRequest,
    deleteProspectSuccess,
    deleteProspectFailed
} = prospects.actions;
