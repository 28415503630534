export const URLS = {
  FRONT_URL: "https://v3.letustradetogether.com",
  BASE_URL: "https://api.letustradetogether.com",
  GEO_STAT_URL: "https://geo.letustradetogether.com/statistics/",
  CLIENT_ID: "rgFz0scCkvH2qAm4iBWwk5m8f6qC9SVM5RHzrKze",
  AUTHS: {
    LOGIN: "auth/login/",
    REFRESH: "auth/token/refresh/",
    LOGOUT: "auth/logout/",
    REGISTRATION: "accounts/registration/",
    MANAGER_REGISTRATION: "accounts/staff/registration/",
    VERIFY_EMAIL: "/accounts/email/verification/",
    VERIFY_ACCOUNT: (id) => `/users/verify/${id}/`,
    resetPassword: "accounts/password-reset/",
    resetPasswordAdmin: "accounts/password/admin-reset/",
    connectUserAccount: "auth/admin-to-customer/"
  },
  STATISTICS: {
    GENERAL: "statistics/generals/",
    RECENT_DEPOSITS: "statistics/recent-deposits/",
    APP: "statistics/app",
  },
  USERS: {
    ME: "users/me/",
    REGISTER: "users/register/direct/",
    notifications: "users/notifications/",
    CC: "users/cc/",
    DETAILS: (id) => `users/${id}/`,
    DELETE: (id) => `users/${id}/destroy/`,
    ASSIGN_TO_MANAGER: (id) => `users/assign-to-manager/${id}/`,
    NAME_AS_TEAM_MANAGER: (id) => `users/team-manager/${id}/`,
    TEAM_LEADER_LIST: (search) => `users/manager-leaders/?search=${search}`,
    LOCK_UNLOCK_USER: (id) => `users/lock-unlock/${id}/`,
    EDIT: (id) => `users/${id}/`,
    PROSPECTS: {
      CREATE: "prospect/",
      LIST: (search, pageSize, page, toConvert) =>
        `prospect/?search=${search}&page_size=${pageSize ?? 25}&page=${
          page ?? 1
        }${toConvert ? "&to_convert=" + toConvert : ""}`,
      UPDATE: (id) => `prospect/${id}/`,
      CONVERT: (id) => `prospect/${id}/convert/`,
      ADMIN_CONVERT: (id) => `prospect/${id}/admin_convert/`,
      STATISTICS: `prospect/statistics/`,
    },
    LIST: (search, page, isManager, isStaff, extraFilters) =>
      `users/?search=${search}&page=${page}&is_manager=${
        isManager ?? false
      }&is_staff=${isStaff ?? false}${extraFilters ?? ""}`,
    UPDATE: (id) => `users/update-info/`,
    NORMAL: (search) => `users/normal-users/?search=${search}`,
    MANAGER: (search) => `users/manager-users/?search=${search}`,
    MY_WALLETS: (walletId) => `users/wallet/?wallet=${walletId}`,
    MY_WALLET_TRANSACTIONS: (walletId) =>
      `users/transactions/?wallet=${walletId}`,
    MY_TRANSACTIONS: "users/transactions/",
    MY_POSITIONS: "users/positions/",
    FILES: {
      CREATE1: "users/files/",
      CREATE: "users/files/user/",
      LIST: "users/files/",
      MY: "users/files/me/",
      EDIT: (id) => `users/files/${id}/`,
      USER: (id) => `users/files/user/${id}/`,
    },
    GROUP: {
      ADD_USER: "/users/add-to-group/",
    },
    BANK_DETAILS: {
      CREATE: "users/bank_account/",
      ME: "users/bank_account/me/",
      EDIT: (id) => `users/bank_account/${id}/`,
      USER: (id) => `users/bank_account/user/${id}/`,
    },
  },
  COUNTRIES: {
    LIST: `countries/api/countries/?page_size=252`,
  },
  CONTACTS: {
    LIST: `contact/`,
  },
  PERMISSIONS: {
    GROUPS: {
      LIST: `/permissions/groups/`,
      GET: (id) => `/permissions/groups/${id}/`,
      DELETE: (id) => `/permissions/groups/${id}/`,
      UPDATE: (id) => `/permissions/groups/${id}/`,
    },
    PERMISSIONS: {
      LIST: `/permissions/perm/?page_size=300`,
      GET: (id) => `/permissions/perm/${id}/`,
      DELETE: (id) => `/permissions/perm/${id}/`,
      UPDATE: (id) => `/permissions/perm/${id}/`,
    },
  },
  WALLETS: {
    LIST: (search, page, currency) =>
      `wallets/wallets/?search=${search}&page=${page ?? 1}&currency=${currency ?? ""}`,
    LIST_NOT_ATTRIBUED: (search, page, currency) =>
      `wallets/userless/?search=${search}&page=${page ?? 1}&currency=${currency ?? ""}`,
    INIT: "wallets/init/",
    CREATE: "wallets/wallets/",
    EDIT: (id) => `wallets/wallets/${id}/`,
    USER: (id) => `wallets/user/${id}/`,
    GLOBALS: `wallets/globals/`,
    DELETE: (id) => `wallets/wallets/${id}/`,
    GET: (id) => `wallets/wallets/${id}/`,
    DEPOSITS: {
      BANK: "wallets/bank-deposit-info/",
      BANK_EDIT: (id) => `wallets/bank-deposit-info/${id}/`,
      CRYPTO_EDIT: (id) => `wallets/crypto-deposit-info/${id}/`,
      CRYPTO: "wallets/crypto-deposit-info/",
    },
  },
  POSITIONS: {
    LIST: "positions/",
    CREATE: "positions/",
    EDIT: (id) => `positions/${id}/`,
    USER: (id) => `positions/user/${id}/`,
    DELETE: (id) => `positions/${id}/`,
    CLOSE: (id) => `positions/${id}/close/`,
    CANCEL: (id) => `positions/${id}/cancel/`,
    GET: (id) => `positions/${id}/`,
  },
  TRANSACTIONS: {
    me: ``
  },
    TRANSACTIONS: {
    LIST: (search) => `transactions/?search=${search}`,
    CREATE: "transactions/",
    EDIT: (id) => `transactions/${id}/`,
    VALIDATE: (id) => `transactions/${id}/validate/`,
    CANCEL: (id) => `transactions/${id}/cancel/`,
    USER: (id) => `transactions/user/${id}/`,
    DELETE: (id) => `transactions/${id}/`,
    GET: (id) => `transactions/${id}/`,
    SWAP: (search) => `swap-transfert/?search=${search}`,
  },
  PROSPECTS: {
    LIST: "prospect/",
    CREATE: "prospect/",
    EDIT: (id) => `prospect/${id}/`,
    DELETE: (id) => `prospect/${id}/`,
    GET: (id) => `prospect/${id}/`,
  },

  MESSAGES: {
    LIST: "message/messages/",
    ROOM_MESSAGES: (roomId) => `message/${roomId}/messages/`,
    CONVERSATION: (user, manager) => `message/?user=${user}&manager=${manager}`,
    CREATE: "message/messages/",
    EDIT: (id) => `message/${id}/`,
    DELETE: (id) => `message/${id}/`,
    GET: (id) => `message/${id}/`,
    ROOMS: {
      MY: "message/my-rooms/",
      LIST: "message/",
      CONVERSATION: (user, manager) =>
        `message/?user=${user}&manager=${manager}`,
      CREATE: "message/rooms/",
      EDIT: (id) => `message/${id}/`,
      DELETE: (id) => `message/${id}/`,
      GET: (id) => `message/${id}/`,
    },
  },
  UTILS: {
    COUNTRIES: {
      LIST: () => `countries/api/countries/?page_size=300`,
    },
  },
  REPORTS: {
    LIST: (search) => `reports/reports/?search=${search}`,
    CREATE: "reports/reports/",
    EDIT: (id) => `reports/reports/${id}/`,
    USER: (id, search, page, date) =>
      `reports/user/${id}/?search=${search}&page=${page ?? 1}${
        date ? "&date=" + date : ""
      }`,
    DELETE: (id) => `reports/reports/${id}/`,
    GET: (id) => `reports/reports/${id}/`,
  },
  TICKETS: {
    user: (user, search, page, status) =>
      `tickets/user/${user}/?search=${search ?? ""}&page=${page ?? 1}${
        status != "ALL" ? "&status=" + status : ""
      }`,
    create: "tickets/create/",
    list: (search, page, status) =>
      `tickets/list/?search=${search ?? ""}&page=${page ?? 1}${
        status != "ALL" ? "&status=" + status : ""
      }`,
  },
  CALL_REQUEST: {
    user: (user, search, page, date) =>
      `call-request/user/${user}/?search=${search ?? ""}&page=${page ?? 1}${
        date ? "&date=" + date : ""
      }`,
    list: (search, page, severity) =>
      `call-request/c-r/?search=${search ?? ""}&page=${page ?? 1}${
        severity != "ALL" ? "&severity=" + severity : ""
      }`,
  },
};
