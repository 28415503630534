import _ from "lodash";
import { toast } from "react-toastify";
import SecureLS from "secure-ls";
import {
  loadingUsersFailed,
  loadingUsersRequest,
  loadingUsersSuccess,
} from "store/reducers/users";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls/urls";

var ls = new SecureLS({ encodingType: "aes" });

export function loadingUsers(search, userType, page, extraFilters) {
  return (dispatch) => {
    dispatch(loadingUsersRequest());
    API.get(
      userType === "NORMAL_USERS"
        ? URLS.USERS.LIST(search, page, false, false, extraFilters ?? "")
        : URLS.USERS.LIST(search, page, true, true, extraFilters ?? "")
    )
      .then((response) => {
        // console.log("action.payload.data \n", action.payload.data);
        dispatch(loadingUsersSuccess({ data: {...response.data, results: _.reverse(response.data.results)}, userType }));
        // history.push('/dashboard');
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.detail ?? "Error while loading users"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(loadingUsersFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function logout(history) {
  return (dispatch) => {
    const idToast = toast.loading("Loging out...");
    ls.removeAll();
    history.push("/");
    window.location.reload();
    // API.get(URLS.AUTHS.LOGOUT, {headers: {"ClientId": URLS.CLIENT_ID}}).then((resp) => {
    //     toast.update(idToast, {render: "Successfully logged out..", type: "success", isLoading: false, autoClose: true});
    //     ls.removeAll();
    //     history.push('/');
    // }).catch((error) => {
    //     ls.removeAll();
    //     history.push('/');
    //     console.log("Error ===============>",  error)
    //     toast.update(idToast, {render: error.response.data.detail ?? "Error while logging ou !! Please try later", type: "error", isLoading: false, autoClose: true });
    // })
  };
}
