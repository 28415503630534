// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  loading_users_request: false,
  loading_users_success: false,
  loading_users_failed: false,
  usersList: {
    count: 0,
    page: 1,
    page_size: 1,
    total: 1,
    actualPage: 1,
    results: [],
  },
  managersList: {
    count: 0,
    pages: 1,
    actualPage: 1,
    results: [],
  },
};

// ==============================|| SLICE - users ||============================== //

const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    loadingUsersRequest(state, action) {
      state.loading_users_request = true;
      state.loading_users_success = false;
      state.loading_users_failed = false;
    },

    loadingUsersSuccess(state, action) {
      state.loading_users_request = false;
      state.loading_users_success = true;
      state.loading_users_failed = false;
      state.usersList = { ...state.usersList, ...action.payload.data };
      state.managersList =
        action.payload.userType === "MANAGER"
          ? { ...state.managersList, ...action.payload.data }
          : { ...state.managersList };
    },
    loadingUsersFailed(state, action) {
      state.loading_users_request = false;
      state.loading_users_success = false;
      state.loading_users_failed = false;
    },
  },
});

export default users.reducer;

export const { loadingUsersRequest, loadingUsersSuccess, loadingUsersFailed } =
  users.actions;
