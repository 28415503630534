// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import userAuth from './userAuth';
import prospects from './prospects';
import countries from './countries';
import contacts from './contact';
import users from './users';
import { MessagesReducers } from './messages';
    

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, userAuth, prospects, countries, contacts, users, messages: MessagesReducers, });

export default reducers;
