import * as React from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import MainCard from "components/MainCard";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import NewBankDepositDetails from "./components/NewBankDepositDetails";
import NewCryptoDepositDetails from "./components/NewCryptoDepositDetails";
import moment from "moment";

export default function DepositDetails() {
  const [bankDeposits, setBankDeposits] = React.useState([]);
  const [cryptoDeposits, setCryptoDeposits] = React.useState([]);

  // Loadings
  const [loadingBank, setLoadingBank] = React.useState(true);
  const [loadingCrypto, setLoadingCrypto] = React.useState(true);

  const handleCreateBankDetailsSuccess = (data, isEdition) => {
    if (isEdition) {
      setBankDeposits((prev) => {
        return prev.map((item) => {
          if (item.id === data.id) {
            item = data;
          }
          return item;
        });
      });
    } else {
      setBankDeposits((prev) => {
        return [...prev, data];
      });
    }
  };

  const handleCreateCryptoDetailsSuccess = (data, isEdition) => {
    if (isEdition) {
      setCryptoDeposits((prev) => {
        return prev.map((item) => {
          if (item.id === data.id) {
            item = data;
          }
          return item;
        });
      });
    } else {
      setCryptoDeposits((prev) => {
        return [...prev, data];
      });
    }
  };

  React.useEffect(() => {
    //   Load deposit bank details
    API.get(URLS.WALLETS.DEPOSITS.BANK)
      .then((resp) => {
        const { data } = resp;
        setBankDeposits(data);
        setLoadingBank(false);
      })
      .catch((error) => {
        setLoadingBank(false);
      });

    // Load deposit crypto details
    API.get(URLS.WALLETS.DEPOSITS.CRYPTO)
      .then((resp) => {
        const { data } = resp;
        setCryptoDeposits(data);
        setLoadingCrypto(false);
      })
      .catch((error) => {
        setLoadingCrypto(false);
      });
  }, []);
  return (
    <Grid container spacing={4}>
      <Grid item md={6} xs={12}>
        <MainCard
          title={
            <Box sx={{ display: "flex" }}>
              <Box sx={{ marginRight: "auto" }}>
                {"Crypto deposit informations"}
              </Box>
              <Box>
                <NewCryptoDepositDetails
                  onSuccess={handleCreateCryptoDetailsSuccess}
                  isEdition={false}
                />
              </Box>
            </Box>
          }
        >
          {loadingCrypto && <LinearProgress />}
          {!loadingCrypto && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>QR code</TableCell>
                    <TableCell>Wallet type</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cryptoDeposits.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <img
                          width={"70px"}
                          src={
                            row?.crypto_deposits?.qr_code
                          }
                          alt=""
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.wallet_type}
                      </TableCell>
                      <TableCell>{row.address}</TableCell>
                      <TableCell>
                        {moment(row?.created_at).format("lll")}
                      </TableCell>
                      <TableCell>
                        <NewCryptoDepositDetails
                          onSuccess={handleCreateCryptoDetailsSuccess}
                          isEdition={true}
                          toEdit={row}
                        />
                      </TableCell>
                      {/* <TableCell>{row.protein}</TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </MainCard>
      </Grid>
      <Grid item md={6} xs={12}>
        <MainCard
          title={
            <Box sx={{ display: "flex" }}>
              <Box sx={{ marginRight: "auto" }}>
                {"Bank deposit informations"}
              </Box>
              <Box>
                <NewBankDepositDetails
                  onSuccess={handleCreateBankDetailsSuccess}
                  isEdition={false}
                />
              </Box>
            </Box>
          }
        >
          {loadingBank && <LinearProgress />}
          {!loadingBank && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Account Name</TableCell>
                    <TableCell>IBAN</TableCell>
                    <TableCell>Swift</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bankDeposits.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.account_name}
                      </TableCell>
                      <TableCell>{row.iban}</TableCell>
                      <TableCell>{row.swift}</TableCell>
                      <TableCell>
                        {moment(row?.created_at).format("lll")}
                      </TableCell>
                      <TableCell>
                        <NewBankDepositDetails
                          onSuccess={handleCreateBankDetailsSuccess}
                          isEdition={true}
                          toEdit={row}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </MainCard>
      </Grid>
    </Grid>
  );
}
