import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import Prospects from "pages/prospects/index";
import UsersList from "pages/users/UsersList";
import UsersDetails from "pages/users/details/UsersDetails";
import Profile from "pages/profile/Profile";
import ContactUs from "pages/contact/ContactUs";
import DepositDetails from "../pages/deposit-details/DepositDetails";
import RestrictedToAdmin from "pages/administration/components/RestrictedToAdmin";
import ManagersList from "pages/administration/pages/ManagersList";
import GroupList from "pages/administration/pages/groups/GroupList";
import ToConvert from "pages/administration/pages/prospects/toConvert";
import AdminTransactions from "pages/administration/pages/transactions/Transactions";
import AdminWallets from "pages/administration/pages/wallets/Wallets";
import ReportsList from "pages/reports/ReportsList";
import GlobalWalletsList from "pages/users/details/components/GlobalWallet";
import CreditCards from "pages/administration/pages/ccards/CCards";
import TicketsList from "../pages/tickets/TicketsList";
import CallRequests from "pages/callRequests/index";
import WalletCurrencies from "pages/administration/pages/wallets/WalletsCurrencies";
import AdminSwapTransactions from "pages/administration/pages/transactions/SwapTransfert";
import Error404 from "pages/errors/not-found";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));

// render - sample page
const SamplePage = Loadable(lazy(() => import("pages/extra-pages/SamplePage")));

// render - utilities
const Typography = Loadable(
  lazy(() => import("pages/components-overview/Typography"))
);
const Color = Loadable(lazy(() => import("pages/components-overview/Color")));
const Shadow = Loadable(lazy(() => import("pages/components-overview/Shadow")));
const AntIcons = Loadable(
  lazy(() => import("pages/components-overview/AntIcons"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: (
        <RestrictedToAdmin>
          <DashboardDefault />
        </RestrictedToAdmin>
      ),
    },
    {
      path: "color",
      element: <Color />,
    },
    {
      path: "users/list",
      element: <UsersList />,
    },
    {
      path: "reports/list",
      element: <ReportsList />,
    },
    {
      path: "users/managers",
      element: (
        <RestrictedToAdmin>
          <ManagersList />
        </RestrictedToAdmin>
      ),
    },
    {
      path: "deposits/wallets",
      element: (
        <RestrictedToAdmin>
          <GlobalWalletsList />
        </RestrictedToAdmin>
      ),
    },
    {
      path: "tickets",
      element: (
        <RestrictedToAdmin>
          <TicketsList />
        </RestrictedToAdmin>
      ),
    },
    {
      path: "transactions/swap",
      element: (
        <RestrictedToAdmin>
          <AdminSwapTransactions />
        </RestrictedToAdmin>
      ),
    },
    {
      path: "call-requests",
      element: (
        <RestrictedToAdmin>
          <CallRequests />
        </RestrictedToAdmin>
      ),
    },
    {
      path: "prospects/to-convert",
      element: (
        <RestrictedToAdmin>
          <ToConvert />
        </RestrictedToAdmin>
      ),
    },
    {
      path: "transactions/list",
      element: (
        <RestrictedToAdmin>
          <AdminTransactions />
        </RestrictedToAdmin>
      ),
    },
    {
      path: "wallets/",
      element: (
        <RestrictedToAdmin>
          <WalletCurrencies />
        </RestrictedToAdmin>
      ),
    },
    ,
    {
      path: "wallets/:currency",
      element: (
        <RestrictedToAdmin>
          <AdminWallets />
        </RestrictedToAdmin>
      ),
    },
    {
      path: "users/groups",
      element: (
        <RestrictedToAdmin>
          <GroupList />
        </RestrictedToAdmin>
      ),
    },
    {
      path: "credit/cards",
      element: (
        <RestrictedToAdmin>
          <CreditCards />
        </RestrictedToAdmin>
      ),
    },
    {
      path: "users/:userId/details",
      element: <UsersDetails />,
    },
    {
      path: "messages",
      element: <ContactUs />,
    },
    // {
    //     path: 'contact',
    //     element: <Contact />
    // },
    // {
    //     path: 'dashboard',
    //     children: [
    //         {
    //             path: 'default',
    //             element: <DashboardDefault />
    //         }
    //     ]
    // },
    {
      path: "sample-page",
      element: <SamplePage />,
    },
    {
      path: "shadow",
      element: <Shadow />,
    },
    {
      path: "typography",
      element: <Typography />,
    },
    {
      path: "icons/ant",
      element: <AntIcons />,
    },
    {
      path: "prospects",
      element: <Prospects />,
    },
    {
      path: "profile",
      element: <Profile />,
    },
    {
      path: "deposit-details",
      element: <DepositDetails />,
    }
  ],
};

export default MainRoutes;
