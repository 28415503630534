import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseOutlined from "@ant-design/icons";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { toast } from "react-toastify";

// material-ui
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  MenuItem,
  Select,
  Divider,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// import { useHistory } from 'react-router';

// project import
// import FirebaseSocial from './FirebaseSocial';
import AnimateButton from "components/@extended/AnimateButton";

// assets
import { useDispatch, useSelector } from "react-redux";

// actions
import { authLoginEffect } from "store/effects/authEffects";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import LoadingButton from "@mui/lab/LoadingButton";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import {
  handleCreateWalletDepositInfo,
  handleEditWalletDepositInfo,
} from "store/effects/walletsEffects";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const FIATS = ["EUR", "USD"];

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined style={{ color: "#000" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function NewWallet({
  wallet,
  isEdition,
  actionButton,
  handleSuccess,
  isGlobal,
}) {
  const [open, setOpen] = React.useState(false);
  const [qrCode, setQrCode] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const {
    login_request,
    auth: { user },
  } = useSelector((state) => state.userAuth);
  const navigate = useNavigate();
  const { userId } = useParams();

  const handleChangeQrCode = (event) => {
    setQrCode(event.target.files[0]);
  };

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div onClick={handleClickOpen}>{actionButton()}</div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {isEdition ? "Edit wallet" : "New wallet"}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={
              isGlobal
                ? {
                    currency: wallet?.currency ?? "USD",
                    address: wallet?.crypto_deposits?.address ?? "",
                    balance: wallet?.balance ?? "",
                    swift: wallet?.deposit_details?.swift ?? "",
                    accountName: wallet?.deposit_details?.account_name ?? "",
                    iban: wallet?.deposit_details?.iban ?? "",
                    is_active: wallet?.is_active ?? true,
                    walletType: wallet?.crypto_deposits?.wallet_type ?? "",
                    submit: null,
                  }
                : {
                    currency: wallet?.currency ?? "BTC",
                    address: wallet?.crypto_deposits?.address ?? "",
                    balance: 0,
                    is_active: wallet?.is_active ?? true,
                    walletType: wallet?.crypto_deposits?.address_type ?? "",
                    submit: null,
                  }
            }
            validationSchema={Yup.object().shape({
              currency: Yup.string()
                .max(255)
                .required("This field is required"),
              // address: Yup.string().max(255).required("This field is required"),
              // balance: Yup.string().max(255).required("This field is required"),
              // is_active: Yup.boolean().required("This field is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                setLoading(true);
                // const data = { ...values, type: "USER", user:  userId};
                const data = new FormData();
                data.append("currency", values.currency);
                // data.append("address", values.address);
                data.append("balance", values.balance);
                data.append("is_active", values.is_active ?? true);
                data.append("type", isGlobal ? "GLOBAL" : "USER");
                if (isGlobal) {
                  data.append("user", user.id);
                } else if (userId) {
                  data.append("user", userId);
                } else {
                }
                data.append("created_by", user.id);

                // Bank deposit data #must append wallet before submitting
                const bankDepositInfos = {
                  swift: values.swift,
                  iban: values.iban,
                  account_name: values.accountName,
                  created_by: user.id,
                  is_active: values.is_active
                };

                // Crypto deposit data #must append wallet before submitting
                var cryptoDepositInfos = new FormData();
                if (qrCode) {
                  cryptoDepositInfos.append("qr_code", qrCode);
                }
                cryptoDepositInfos.append("created_by", user.id);
                cryptoDepositInfos.append("address", values.address);
                cryptoDepositInfos.append("wallet_type", values.walletType);

                if (wallet && isEdition) {
                  API.patch(URLS.WALLETS.EDIT(wallet?.id), data)
                    .then((reps) => {
                      setStatus({ success: true });
                      setSubmitting(true);
                      // Update deposit infos
                      if (FIATS.includes(reps.data.currency)) {
                        if (wallet?.deposit_details?.id) {

                            handleEditWalletDepositInfo(
                              wallet?.deposit_details?.id,
                              reps.data,
                              {
                                ...bankDepositInfos,
                                wallet: reps.data.id,
                              }
                            ).then((reps) => {
                              if(handleSuccess){  
                                handleSuccess(reps.data);
                              }
                              toast.success("Wallet edition successfuly!");
                              setStatus({ success: false });
                              setSubmitting(false);
                              setLoading(false);
                            }).catch((error) => {
                              toast.error(error?.data?.details ?? error?.data ?? "Wallet edition failed check all required fields and try again!");
                              setStatus({ success: false });
                              setSubmitting(false);
                              setLoading(false);
                            });
                          }

                      } else {
                        if (wallet?.crypto_deposits?.id) {
                          try {
                            cryptoDepositInfos.append("wallet", reps.data.id);
                            handleEditWalletDepositInfo(
                              wallet?.crypto_deposits?.id,
                              reps.data,
                              cryptoDepositInfos,
                              handleSuccess
                            ).then((reps) => {
                              if(handleSuccess){  
                                handleSuccess(reps.data);
                              }
                              toast.success("Wallet edition successfuly!");
                              setStatus({ success: false });
                              setSubmitting(false);
                              setLoading(false);
                            }).catch((error) => {
                              toast.error(error?.data?.details ?? error?.data ?? "Wallet edition failed check all required fields and try again!");
                              setStatus({ success: false });
                              setSubmitting(false);
                              setLoading(false);
                            });;
                            setStatus({ success: false });

                          } catch (error) {
                            toast.error(error?.data?.details ?? "Wallet edition failed!");
                            setStatus({ success: false });
                            setErrors({ submit: error.message });
                          }
                        }
                      }

                      
                      setSubmitting(false);
                      setLoading(false);
                    })
                    .catch((error) => {
                      toast.error(error?.data?.details ?? "Wallet edition failed!");
                      setLoading(false);
                      setStatus({ success: false });
                    });
                } else {
                  API.post(URLS.WALLETS.CREATE, data).then((reps) => {
                    setStatus({ success: true });
                    setSubmitting(true);
                    if (handleSuccess) {
                      handleSuccess(reps.data);
                    }
                    setLoading(false);
                    if (FIATS.includes(reps.data.currency)) {
                      handleCreateWalletDepositInfo(
                        reps.data,
                        {
                          ...bankDepositInfos,
                          wallet: reps.data.id,
                        },
                        handleSuccess
                      );
                    } else {
                      cryptoDepositInfos.append("wallet", reps.data.id);
                      handleCreateWalletDepositInfo(
                        reps.data,
                        cryptoDepositInfos
                      );
                    }
                    handleClose();
                  });
                }
              } catch (err) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
                setLoading(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="currency-login">Currency</InputLabel>
                      <Select
                        id="currency-login"
                        // type="email"
                        value={values.currency}
                        name="currency"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Currency"
                        fullWidth
                        error={Boolean(touched.currency && errors.currency)}
                      >
                        <MenuItem value="BTC">Bitcoin</MenuItem>
                        <MenuItem value="ETH">Ethereum</MenuItem>
                        <MenuItem value="USDT">Tether</MenuItem>
                          <MenuItem value="USD">United states dollar</MenuItem>
                        {/* {!isGlobal && (
                        )} */}
                        <MenuItem value="EUR">Euro</MenuItem>
                      </Select>
                      {touched.currency && errors.currency && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-currency-login"
                        >
                          {errors.currency}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  {!isGlobal && (
                    <Grid item md={6} xs={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="balance-login">Balance</InputLabel>
                        <OutlinedInput
                          fullWidth
                          error={Boolean(touched.balance && errors.balance)}
                          id="balance-login"
                          // type={showPassword ? 'text' : 'password'}
                          value={values.balance}
                          type="number"
                          min={0}
                          name="balance"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Wallet balance"
                        />
                        {touched.balance && errors.balance && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-balance-login"
                          >
                            {errors.balance}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                  )}
                  <Grid item md={12}>
                    <Divider />
                  </Grid>
                  <Grid item md={12}>
                    <Typography variant="h3">Deposit informations</Typography>
                  </Grid>
                  {!FIATS.includes(values.currency) && (
                    <>
                      <Grid item md={6} xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="address-login">
                            Address *
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            error={Boolean(touched.address && errors.address)}
                            id="address-login"
                            value={values.address}
                            name="address"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Wallet or bank address"
                          />
                          {touched.address && errors.address && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-address-login"
                            >
                              {errors.address}
                            </FormHelperText>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="qrCode-login">
                            QR code
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            id="qr-code-login"
                            // type={showPassword ? 'text' : 'password'}
                            // value={values.balance}
                            type="file"
                            onChange={handleChangeQrCode}
                            placeholder="QR code image"
                            inputProps={{ accept: "image/*" }}
                          />
                        </Stack>
                      </Grid>
                      {values.currency === "USDT" && <Grid item md={6} xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="wallet-type-login">
                            Wallet address type
                          </InputLabel>
                          <Select
                            id="wallet-type-login"
                            // type="email"
                            value={values.walletType}
                            name="walletType"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Wallet type"
                            fullWidth
                            error={Boolean(
                              touched.walletType && errors.walletType
                            )}
                          >
                            <MenuItem value={isGlobal ? "ERC 20" : "ERC20"}>
                              ERC20
                            </MenuItem>
                            <MenuItem value={isGlobal ? "TRC 20" : "TRC20"}>
                              TRC20
                            </MenuItem>
                          </Select>
                          {touched.walletType && errors.walletType && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-walletType-login"
                            >
                              {errors.walletType}
                            </FormHelperText>
                          )}
                        </Stack>
                      </Grid>}
                    </>
                  )}
                  {FIATS.includes(values.currency) && (
                    <>
                      <Grid item md={6} xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="accountName-login">
                            Account Name *
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            error={Boolean(
                              touched.accountName && errors.accountName
                            )}
                            id="accountName-login"
                            // type={showPassword ? 'text' : 'password'}
                            value={values.accountName}
                            name="accountName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Bank account name"
                          />
                          {touched.accountName && errors.accountName && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-accountName-login"
                            >
                              {errors.accountName}
                            </FormHelperText>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="swift-login">Swift *</InputLabel>
                          <OutlinedInput
                            fullWidth
                            error={Boolean(touched.swift && errors.swift)}
                            id="swift-login"
                            // type={showPassword ? 'text' : 'password'}
                            value={values.swift}
                            name="swift"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Swift"
                          />
                          {touched.swift && errors.swift && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-swift-login"
                            >
                              {errors.swift}
                            </FormHelperText>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="iban-login">Iban *</InputLabel>
                          <OutlinedInput
                            fullWidth
                            error={Boolean(touched.iban && errors.iban)}
                            id="iban-login"
                            // type={showPassword ? 'text' : 'password'}
                            value={values.iban}
                            name="iban"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Iban"
                          />
                          {touched.iban && errors.iban && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-iban-login"
                            >
                              {errors.iban}
                            </FormHelperText>
                          )}
                        </Stack>
                      </Grid>
                    </>
                  )}

                  {/* <Grid item md={6} xs={12} sx={{ mt: "0" }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.is_active}
                            onChange={(event) =>
                              setFieldValue("is_active", event.target.checked)
                            }
                            name="is_active"
                            color="primary"
                            size="small"
                          />
                        }
                        label={
                          <Typography variant="h6">Is wallet active</Typography>
                        }
                        e
                      />
                    </Stack>
                  </Grid> */}
                  <Grid item md={6} xs={12} sx={{ mt: "0" }}>
                    {(isEdition && !FIATS.includes(wallet?.currency)) && (
                      <img
                        height={"200px"}
                        width={"200px"}
                        src={!isGlobal ? wallet?.qr_code : wallet?.crypto_deposits?.qr_code}
                        alt="qr code"
                      />
                    )}
                  </Grid>
                  {
                    (isEdition ) && (
                      <Grid item md={6} xs={12} sx={{ mt: "0" }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.is_active}
                              onChange={handleChange}
                              name="is_active"
                              color="primary"
                              size="small"
                            />
                          }
                          label={
                            <Typography variant="h6">Is wallet active</Typography>
                          }
                        />
                      </Grid>
                    )
                  }
                  {errors.submit && (
                    <Grid item xs={12}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{ height: "0px", visibility: "hidden" }}
                  >
                    <AnimateButton>
                      <Button
                        disableElevation
                        disabled={login_request}
                        id="submitButton"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Submit
                        {/* {login_request && <CircularLo /> } */}
                      </Button>
                    </AnimateButton>
                  </Grid>
                  {/* <Grid item xs={12}>
                                <Divider>
                                    <Typography variant="caption"> Login with</Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <FirebaseSocial />
                            </Grid> */}
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            onClick={() => {
              const submitButton = document.getElementById("submitButton");
              if (submitButton) {
                submitButton.click();
              }
            }}
          >
            {isEdition ? "Update" : "Save"}
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
