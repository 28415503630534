import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MoreOutlined } from "@ant-design/icons";
import NewUserGroup from "./NewUserGroup";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";



const ITEM_HEIGHT = 48;

export default function GroupListActions({ group, onSuccess, onDeleteSuccess }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const toastId = React.useRef(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDelete = (id) => {
    handleClose()
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )
        toastId.current = toast("Deleting...", {
          autoClose: false,
          closeButton: true 
        });

        API.delete(URLS.PERMISSIONS.GROUPS.DELETE(id)).then((resp) => {

          toast.update(toastId.current, {
            render: "Group successfuly deleted.",
            type: toast.TYPE.SUCCESS,
            autoClose: 5000,
            toastId: toastId,
          });
          onDeleteSuccess(id)
        }).catch((error) => {
          toast.update(toastId.current, {
            render: error?.response?.detail ?? "Error while deleting the group.",
            type: toast.TYPE.ERROR,
            autoClose: 5000,
            toastId: toastId,
          });
        })


      }
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreOutlined />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <NewUserGroup onSuccess={onSuccess} isEdition={true} toEdit={group} />
        <MenuItem onClick={() => handleDelete(group.id)}>Delete</MenuItem>
      </Menu>
    </div>
  );
}
