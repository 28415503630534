// material-ui
import { Typography } from "@mui/material";

// project import
import MainCard from "components/MainCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadingCountriesEffect } from "store/effects/countriesEffects";
import { loadingUsers } from "store/effects/usersEffect";
import {
  convertProspectEffect,
  deleteProspectEffect,
  editProspectEffect,
  listProspectEffect,
} from "store/effects/prospectEffects";

// third-party
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import CreateProspect from './createProspect';
import { LinearProgress, CircularProgress } from "@mui/material";

import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Tooltip from "@mui/material/Tooltip";
// material-ui
import {
  Box,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  TextField,
  Checkbox,
  Select,
  FormControl,
  Grid,
  InputLabel,
  Autocomplete,
  Badge,
} from "@mui/material";

// Icons
import {
  DeleteOutlined,
  UserSwitchOutlined,
  CopyOutlined,
} from "@ant-design/icons";

// project import
import Dot from "components/@extended/Dot";
import _ from "lodash";
// import EditProspect from './editProspect';
import LoadingButton from "@mui/lab/LoadingButton";
import SecureLS from "secure-ls";
import moment from "moment";
import { useNavigate } from "react-router";
import UsersListActions from "./components/UsersOptions";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import CopyButton from "components/@extended/CopyButton";
import NewUser from "pages/administration/pages/components/NewUser";

// import {}

let ls = new SecureLS({ encodingType: "aes" });
const label = { inputProps: { "aria-label": "Checkbox demo" } };

// ==============================|| PROSPECTS LIST ||============================== //

function OrderTableHead({ handleSelectAll, isAllSelected }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          {" "}
          <Checkbox
            {...label}
            checked={isAllSelected}
            onClick={() => handleSelectAll(isAllSelected)}
          />
        </TableCell>
        <TableCell>First name</TableCell>
        <TableCell>Last name</TableCell>
        <TableCell>Email</TableCell>
        <TableCell>Phone number</TableCell>
        <TableCell>Last login</TableCell>
        <TableCell>Account status</TableCell>
        <TableCell>Is Active</TableCell>
        <TableCell>Country</TableCell>
        <TableCell>Joined at</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

OrderTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

// ==============================|| ORDER TABLE - STATUS ||============================== //

const OrderStatus = ({ status }) => {
  let color;
  let title;

  switch (status) {
    case 0:
      color = "warning";
      title = "Pending";
      break;
    case 1:
      color = "success";
      title = "Approved";
      break;
    case 2:
      color = "error";
      title = "Rejected";
      break;
    default:
      color = "primary";
      title = "None";
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Dot color={color} />
      <Typography>{title}</Typography>
    </Stack>
  );
};

OrderStatus.propTypes = {
  status: PropTypes.number,
};

// ==============================|| ORDER TABLE ||============================== //

export default function UsersList() {
  const [order] = useState("asc");
  const [orderBy] = useState("trackingNo");
  const [selected] = useState([]);
  const [selectedProspect, setSelectedProspect] = useState([]);
  const [managers, setManagers] = useState([]);
  const [managedBy, setManagedBy] = useState(null);
  const [loadingManagedBy, setLoadingManagedBy] = useState(true);
  const [filterMagaer, setFilterMagaer] = useState("");
  // const [selectedProspect, setSelectedProspect] = useState([]);
  const [search, setSearch] = useState("");
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [open, setOpen] = useState({ open: false, id: "" });
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState("");
  const [userSex, setUserSex] = useState("");
  const [country, setCountry] = useState(null);
  const [joinedDate, setJoinedDate] = useState("");

  const {
    auth: { user },
  } = useSelector((state) => state.userAuth);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(+event.target.value);
    setPage(0);
  };
  // handle change bulk status
  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  const {
    loading_users_request,
    delete_propect_success,
    delete_propect_request,
    edit_propect_request,

    usersList: { results, count, page: page1 },
  } = useSelector((state) => state.users);
  const { countriesList, loading_countries_success } = useSelector(
    (state) => state.countries
  );

  const { groups } = ls.get("user-details");
  const navigate = useNavigate();
  // console.log('user ===========> ', user);
  const handleSelectProspect = (prospect) => {
    setSelectedProspect((prev) => [...prev, prospect.id]);
    if (selectedProspect.length === results.length) {
      setIsAllSelected(true);
    }

    // setIsAllSelected(true);
  };
  const handleDeSelectProspect = (id) => {
    setIsAllSelected(false);
    if (selectedProspect.includes(id)) {
      setSelectedProspect((prev) => prev.filter((i) => i !== id));
      setIsAllSelected(false);
    }
  };
  const handleSelectAllProspects = (isAllSelected) => {
    if (isAllSelected) {
      setSelectedProspect([]);
      setIsAllSelected(false);
    } else {
      setIsAllSelected(true);
      results.forEach((prospect) => {
        handleSelectProspect(prospect, true);
      });
    }
  };
  const dispatch = useDispatch();
  const onConvertSuccess = (id) => {
    setSelectedProspect((prev) => prev.filter((i) => i !== id));
    if (selectedProspect.length !== results.length) {
      setIsAllSelected(false);
    }
  };

  const handleSuccess = () => {

    dispatch(loadingUsers(search, "NORMAL_USERS", page + 1));
  }

  const handleFilter = () => {
    const extraFilters = `&account_type=${status ?? ""}&gender=${
      userSex ?? ""
    }&country=${
      String(country?.url).split("/").pop()[
        String(country?.url).split("/").pop().length
      ] ?? ""
    }&data_join__icontains=${joinedDate ?? ""}&managed_by=${managedBy?.id ?? ""}`;
    dispatch(loadingUsers(search, "NORMAL_USERS", page + 1, extraFilters));
  };

  const handleConvertProspect = (prospect) => {
    setSelectedProspect((prev) => [...prev, prospect.id]);
    dispatch(
      editProspectEffect(
        { to_convert: true, id: prospect.id },
        onConvertSuccess
      )
    );
  };
  const handleConvertProspectAll = () => {
    selectedProspect.forEach((element) => {
      dispatch(
        editProspectEffect({ to_convert: true, id: element }, onConvertSuccess)
      );
    });
  };
  useEffect(() => {
    dispatch(loadingCountriesEffect());
    
  }, []);
  useEffect(() => {
    if(user?.is_superuser){
      setLoadingManagedBy(true)
      // Load managers
      API.get(URLS.USERS.LIST(filterMagaer, 1, true, true, "")).then(resp => {
        setManagers(resp.data.results);
        setLoadingManagedBy(false)
      }).catch((error) => {
        setLoadingManagedBy(false);
      })
    }
  }, [filterMagaer]);
  useEffect(() => {
    dispatch(loadingUsers(search, "NORMAL_USERS", page + 1));
  }, [pageSize, page]);

  useEffect(() => {
    if (delete_propect_success) {
      setOpen({ open: false, id: "" });
    }
  }, [delete_propect_success]);

  const isSelected = (trackingNo) => selected.indexOf(trackingNo) !== -1;

  return (
    <Grid container spacing={4}>
      <Grid item md={12}>
        <NewUser isNormalUser={true} handleSuccess={handleSuccess} actionButton={() => <Button color="primary" variant="contained" >Add a new user</Button>} />
      </Grid>
      <Grid item md={12}>
        <Grid>
          <Typography variant="h4">Filters</Typography>
          <Stack direction={{ xs: "column", xs: "row" }} spacing={2}>
            <Box>
              <InputLabel>Search</InputLabel>
              <TextField
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                id="outlined-basic"
                placeholder="Search by name, user id, email"
                variant="outlined"
              />
            </Box>
            {user?.is_superuser && <Box sx={{ width: "200px" }}>
              <InputLabel>Managed by</InputLabel>
              <FormControl
                sx={{
                  "& .MuiInputBase-root": {
                    paddingTop: "3px !important",
                    paddingBottom: "3px !important",
                  },
                }}
                fullWidth
              >
                <Autocomplete
                  loading={loadingManagedBy}
                  disablePortal
                  id="combo-box-managedBy"
                  value={managedBy}
                  onChange={(event, newValue) => {
                    setManagedBy(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setFilterMagaer(newInputValue);
                  }}          
                  options={managers}
                  size="40px"
                  getOptionLabel={(option) => option.first_name + " " + option.last_name}
                  fullWidth
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
            </Box>}
            <Box sx={{ width: "200px" }}>
              <InputLabel>Account type</InputLabel>
              <FormControl fullWidth>
                <Select
                  labelId="bulk-change-status-label"
                  id="bulk-change-status"
                  value={status}
                  name="status"
                  onChange={handleChange}
                  // error={Boolean(touched.status && errors.status)}
                >
                  {[
                    "STARTER",
                    "CLASSIC",
                    "INTERMEDIATE",
                    "ADVANCED",
                    "ULTIMATE",
                  ].map((item, index) => (
                    <MenuItem key={"menu-item-status-" + index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "200px" }}>
              <InputLabel>User sex</InputLabel>
              <FormControl fullWidth>
                <Select
                  labelId="userSex-label"
                  id="userSex"
                  value={userSex}
                  name="userSex"
                  onChange={(event) => setUserSex(event.target.value)}
                  // error={Boolean(touched.status && errors.status)}
                >
                  {["", "MALE", "FEMALE"].map((item, index) => (
                    <MenuItem key={"menu-item-gender-" + index} value={item}>
                      {item !== "" ? item : "ANY SEX"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "200px" }}>
              <InputLabel>Country</InputLabel>
              <FormControl
                sx={{
                  "& .MuiInputBase-root": {
                    paddingTop: "3px !important",
                    paddingBottom: "3px !important",
                  },
                }}
                fullWidth
              >
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={country}
                  onChange={(event, newValue) => {
                    setCountry(newValue);
                  }}
                  options={countriesList}
                  size="40px"
                  getOptionLabel={(option) => option?.name}
                  fullWidth
                  renderInput={(params) => <TextField {...params} />}
                />
                {/* <Select
                  id="country-select"
                  value={country}
                  name="country"
                  onChange={(event) => setCountry(event.target.value)}
                  // error={Boolean(touched.status && errors.status)}
                >
                  {countriesList?.map((item, index) => (
                    <MenuItem
                      key={"menu-item-country" + item.slug}
                      value={
                        String(item.url).split("/").pop()[
                          String(item.url).split("/").pop().length
                        ]
                      }
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select> */}
              </FormControl>
            </Box>
            <Box sx={{ width: "200px" }}>
              <InputLabel>Joined date</InputLabel>
              <FormControl fullWidth>
                <TextField
                  labelId="joinedDate-label"
                  id="joinedDate"
                  value={joinedDate}
                  type="date"
                  name="joinedDate"
                  onChange={(event) => setJoinedDate(event.target.value)}
                  // error={Boolean(touched.status && errors.status)}
                />
              </FormControl>
            </Box>
            <Box>
              <Button
                onClick={handleFilter}
                sx={{ marginTop: 2.5, height: "40px" }}
                variant="contained"
                color="primary"
              >
                Filter
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <MainCard
          sx={{ "& .MuiCardHeader-action": { width: "90%" } }}
          title="Users list"
          secondary={
            <Box
              sx={{
                display: "flex",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                {selectedProspect.length >= 1 ? (
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ marginRight: "1rem" }}
                  >
                    {edit_propect_request && selectedProspect.length > 0 ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Tooltip title="Convert prospect to user">
                        <IconButton onClick={() => handleConvertProspectAll()}>
                          <UserSwitchOutlined style={{ color: "green" }} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {/* {loading_countries_success && <EditProspect prospect={row} groups={groups} />} */}
                    <Tooltip title="Delete user">
                      <IconButton onClick={() => setOpen({ open: true })}>
                        <DeleteOutlined />
                      </IconButton>
                    </Tooltip>

                    <Dialog
                      open={open.open}
                      onClose={() => setOpen({ open: false, id: "" })}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Do you really want to delete selected prospects?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          This action is irreversible. Are you sure to continue
                          ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => setOpen({ open: false, id: "" })}
                        >
                          Cancel
                        </Button>
                        <LoadingButton
                          loading={delete_propect_request}
                          variant="primary"
                          color="error"
                          onClick={() => {
                            selectedProspect.forEach((element) => {
                              dispatch(
                                deleteProspectEffect({ id: element }),
                                onConvertSuccess
                              );
                            });
                          }}
                        >
                          Yes, delete
                        </LoadingButton>
                      </DialogActions>
                    </Dialog>
                    {/* bulk Change status */}
                    <Box sx={{ width: "200px" }}>
                      <FormControl fullWidth>
                        <Select
                          labelId="bulk-change-status-label"
                          id="bulk-change-status"
                          // value={status}
                          defaultValue={"NORMAL_USERS"}
                          name="status"
                          label="Status"
                          onChange={(event) => {
                            const value = event.target.value;
                            if (value !== "NO STATUS") {
                              selectedProspect.forEach((element) => {
                                dispatch(
                                  editProspectEffect(
                                    { status: value, id: element },
                                    onConvertSuccess
                                  )
                                );
                              });
                            }
                          }}
                          // error={Boolean(touched.status && errors.status)}
                        >
                          {["MANAGER", "NORMAL_USERS"].map((item, index) => (
                            <MenuItem key={"menu-item-" + index} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: "flex" }}>
                      <Typography sx={{ m: "auto" }}>
                        {selectedProspect.length} Users
                        {selectedProspect.length > 1 ? "s" : ""} selected
                      </Typography>{" "}
                    </Box>
                  </Stack>
                ) : (
                  <div />
                )}
              </Box>
              <Stack direction="row" spacing={2}>
                {/* <CreateProspect groups={groups} /> */}
              </Stack>
            </Box>
          }
        >
          {loading_users_request && <LinearProgress />}
          {/* {list_propect_request && results.length === 0 && <LinearProgress />} */}
          <Box>
            <TableContainer
              sx={{
                width: "100%",
                overflowX: "auto",
                position: "relative",
                display: "block",
                maxWidth: "100%",
                "& td, & th": { whiteSpace: "nowrap" },
              }}
            >
              <Table
                aria-labelledby="tableTitle"
                sx={{
                  "& .MuiTableCell-root:first-child": {
                    pl: 2,
                  },
                  "& .MuiTableCell-root:last-child": {
                    pr: 3,
                  },
                }}
              >
                <OrderTableHead
                  order={order}
                  orderBy={orderBy}
                  handleSelectAll={handleSelectAllProspects}
                  isAllSelected={isAllSelected}
                />
                <TableBody>
                  {results.map((row1, index) => {
                    const row = { ...row1 };
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${row.id}`;
                    row.countryDetails = _.filter(countriesList, (item) =>
                      item.url.includes(`/${row.country}/`)
                    )[0];

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        aria-checked={selectedProspect.includes(row.id)}
                        tabIndex={-1}
                        key={row.id}
                        // onClick={() => navigate(`/users/${row.id}/details`)}
                        selected={selectedProspect.includes(row.id)}
                      >
                        <TableCell>
                          {" "}
                          <Stack direction="row" spacing={3}>
                            <Checkbox
                              {...label}
                              checked={selectedProspect.includes(row.id)}
                              onClick={() =>
                                selectedProspect.includes(row.id)
                                  ? handleDeSelectProspect(row.id)
                                  : handleSelectProspect(row)
                              }
                            />
                            <CopyToClipboard text={row.id}>

                            <IconButton>
                              <CopyOutlined/>
                            </IconButton>
                            </CopyToClipboard>
                            {row.is_online && (
                              <Box>
                                <Badge
                                  sx={{ top: "20%" }}
                                  badgeContent={"Online"}
                                  color="success"
                                ></Badge>
                              </Box>
                            )}
                            {/* {!row.managed_by && (
                              <Box>
                                <Badge
                                  sx={{ top: "20%" }}
                                  badgeContent={"New"}
                                  color="primary"
                                ></Badge>
                              </Box>
                            )} */}
                          </Stack>
                        </TableCell>
                        <TableCell
                          onClick={() => navigate(`/users/${row.id}/details`)}
                          align="left"
                        >
                          {row.first_name}
                        </TableCell>
                        <TableCell
                          onClick={() => navigate(`/users/${row.id}/details`)}
                          align="left"
                        >
                          {row.last_name}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          align="left"
                        >
                          <a
                            style={{ color: "#262626" }}
                            href={`mailto:${row.email}`}
                            target="_blank"
                          >
                            {row.email} {" "}
                        <CopyButton text={row.email} title={"Copy the email"} />
                          </a>
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => navigate(`/users/${row.id}/details`)}
                        >
                          {row.phone_number}
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => navigate(`/users/${row.id}/details`)}
                        >
                          {row.last_login ? moment(row.last_login).fromNow() : "NEVER"}
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => navigate(`/users/${row.id}/details`)}
                        >
                          {row.account_verified ? 'VERIFIED' : 'VERIFIED'}
                        </TableCell>
                        <TableCell align="left">
                          {row.is_active ? "YES" : "NO"}
                        </TableCell>
                        <TableCell align="left">
                          {row.country && <img alt={row.country?.name} width={'40px'} src={`https://cdn.infinixtrade.net/assets/flags/4x3/${String(row.country?.code2).toLowerCase()}.svg`} />}
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => navigate(`/users/${row.id}/details`)}
                        >
                          {moment(row.date_joined).format("lll")}
                        </TableCell>
                        <TableCell align="left" sx={{ display: "flex" }}>
                          <UsersListActions user={row} />
                          {/* {edit_propect_request &&
                          selectedProspect.includes(row.id) ? (
                            <CircularProgress size={20} />
                          ) : (
                            <Tooltip title="View more details">
                              <IconButton
                                component={Link}
                                to={`/users/${row.id}/details`}
                              >
                                <EyeOutlined style={{ color: "green" }} />
                              </IconButton>
                            </Tooltip>
                          )}
                          {/* {loading_countries_success && <EditProspect prospect={row} groups={groups} />} *
                          <Tooltip title="Delete user">
                            <IconButton
                              onClick={() =>
                                setOpen({ open: true, id: row.id })
                              }
                            >
                              <DeleteOutlined />
                            </IconButton>
                          </Tooltip>
                          <Dialog
                            open={open.open}
                            onClose={() => setOpen({ open: false, id: "" })}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              {"Do you really want to delete?"}
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                This action is irreversible. Are you sure to
                                continue ?
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => setOpen({ open: false, id: "" })}
                              >
                                Cancel
                              </Button>
                              <LoadingButton
                                loading={delete_propect_request}
                                variant="primary"
                                color="error"
                                onClick={() =>
                                  dispatch(deleteProspectEffect(row))
                                }
                              >
                                Yes, delete
                              </LoadingButton>
                            </DialogActions>
                          </Dialog> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={count}
                rowsPerPage={pageSize}
                page={page1 - 1 ?? 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
            {delete_propect_success && results.length === 0 && (
              <Typography variant="body2"> No users for the moment</Typography>
            )}
          </Box>
        </MainCard>
      </Grid>
    </Grid>
  );
}
