import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseOutlined from "@ant-design/icons";

// material-ui
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// import { useHistory } from 'react-router';

// project import
// import FirebaseSocial from './FirebaseSocial';
import AnimateButton from "components/@extended/AnimateButton";

// assets
import { useDispatch, useSelector } from "react-redux";

// actions
import { useParams } from "react-router";
import LoadingButton from "@mui/lab/LoadingButton";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { toast } from "react-toastify";
import CopyButton from "components/@extended/CopyButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined style={{ color: "#000" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function NewUserFile({
  wallet,
  isEdition,
  actionButton,
  handleSuccess,
}) {
  const [open, setOpen] = React.useState(false);
  const [qrCode, setQrCode] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const { login_request } = useSelector((state) => state.userAuth);

  const { userId } = useParams();

  const handleChangeQrCode = (event) => {
    setQrCode(event.target.files[0]);
  };

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div onClick={handleClickOpen}>{actionButton()}</div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {isEdition ? "Edit wallet" : "New wallet"}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              fileType: wallet?.file_type ?? "",
            }}
            validationSchema={Yup.object().shape({
              fileType: Yup.string()
                .max(255)
                .required("This field is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                setLoading(true);
                // const data = { ...values, type: "USER", user:  userId};
                const data = new FormData();
                data.append("file_type", values.fileType);
                data.append("user", userId);
                if (qrCode) {
                  data.append("file", qrCode);
                }

                if (wallet && isEdition) {
                  API.patch(URLS.USERS.FILES.EDIT(wallet?.id), data).then(
                    (reps) => {
                      setStatus({ success: true });
                      setSubmitting(true);
                      if (handleSuccess) {
                        handleSuccess(reps.data);
                      }
                      setLoading(false);
                      handleClose();
                      toast.success("File successfully updated");
                    }
                  );
                } else {
                  API.post(URLS.USERS.FILES.CREATE, data).then((reps) => {
                    setStatus({ success: true });
                    setSubmitting(true);
                    if (handleSuccess) {
                      handleSuccess(reps.data);
                    }
                    setLoading(false);
                    handleClose();
                    toast.success("File successfully added");
                  });
                }
              } catch (err) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
                setLoading(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="fileType-login">
                        File type
                      </InputLabel>
                      <Select
                        id="fileType-login"
                        // type="email"
                        value={values.fileType}
                        name="fileType"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Currency"
                        fullWidth
                        error={Boolean(touched.fileType && errors.fileType)}
                      >
                        {[
                          "BANK STATEMENT",
                          "BANK WIRE",
                          "CREDIT CARD FRONT",
                          "DRIVING LICENSE",
                          "NATIONAL ID CARD",
                          "PASSPORT ID",
                          "UTILITY BILL",
                          "DOD",
                          "WIRE DETAILS",
                          "WD REQUEST FORM",
                          "TIN",
                          "credit card back",
                          "ID",
                        ].map((item, index) => {
                          return (
                            <MenuItem key={"itm" + index} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {touched.fileType && errors.fileType && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-fileType-login"
                        >
                          {errors.fileType}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="qrCode-login">File</InputLabel>
                      <OutlinedInput
                        fullWidth
                        id="qr-code-login"
                        // type={showPassword ? 'text' : 'password'}
                        // value={values.balance}
                        type="file"
                        onChange={handleChangeQrCode}
                        placeholder="QR code image"
                        inputProps={{ accept: "image/*" }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12} sx={{ mt: "0" }}>
                    {isEdition && (
                      <div style={{width: '300px', display: 'flex'}}>
                      <Typography sx={{width: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'noWrap'}}>

                      {wallet.file}
                      </Typography>
                      {" "}
                      <CopyButton text={wallet.file} title={"Copy the file link"} />
                      </div>
                    )}
                  </Grid>
                  {errors.submit && (
                    <Grid item xs={12}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{ height: "0px", visibility: "hidden" }}
                  >
                    <AnimateButton>
                      <Button
                        disableElevation
                        disabled={login_request}
                        id="submitButton"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Submit
                        {/* {login_request && <CircularLo /> } */}
                      </Button>
                    </AnimateButton>
                  </Grid>
                  {/* <Grid item xs={12}>
                                <Divider>
                                    <Typography variant="caption"> Login with</Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <FirebaseSocial />
                            </Grid> */}
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            onClick={() => {
              const submitButton = document.getElementById("submitButton");
              if (submitButton) {
                submitButton.click();
              }
            }}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
